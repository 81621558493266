import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import LandingSectionTitle from '../components/common/section-title';

const ProductExtraFeatures = (props) => {

    const {
        title,
        features,
        color,
    } = props;

    return (
        <>
            <section className="product-section-extra-features height d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <LandingSectionTitle
                                        text={title}
                                        alignment="center"
                                        theme="light"
                                    />
                                </Col>
                                    
                                {features.map((feature, i) =>
                                    <Col key={i}>
                                        <ProductFeature color={(!color) ? 'gray' : color} {...feature} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

const ProductFeature = (props) => {
    const {
        title,
        description,
        icon,
        color,
    } = props;

    return (
        <>
            <div className="queo-access-product-extra-feature">
                <div className={['feature-icon', color].join(' ')}><i className={icon}></i></div>
                <div className="feature-info-container">
                    <h3 className="feature-title">{title}</h3>
                    <p className="feature-description">{description}</p>
                </div>
            </div>
        </>
    );
};

export default ProductExtraFeatures;