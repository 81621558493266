import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import {
    HeroProduct,
    Intro,
    ProductExtraFeatures,
    ProductVideo,
    ProductFeatureHighlight,
} from '../sections/LandingCommonSections';

const LandingParking = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroProduct video="access" mainTitle="Haz de tu parqueadero un espacio inteligente con"
                    title="Queo Parking" product="queo parking" customClass="parking" />
                <Intro intro="Optimiza tus espacios de parqueaderos privados a través de nuestra plataforma, la cual te ayudará a tener más control de tus espacios y además, gracias a nuestra completa red de parqueaderos colaborativos podrás encontrar estacionamientos disponibles cerca a tu zona para tener una mayor oferta de parqueaderos para tus empleados." />
                <ProductExtraFeatures color="primary" title="Optimización de parqueaderos privados" features={extraFeatures} />
                <ProductFeatureHighlight
                    title="Queo Parking también te permite"
                    images={{
                        mobile: 'dashboard.webp',
                        tablet: 'dashboard.webp',
                        desktop: 'dashboard.webp'
                    }}
                    imagePosition="left"
                    page="parking"
                    features={features}
                    product="queo parking"
                />
                <ProductVideo bg="dark" video="access" title="Conoce cómo funciona" subtitle="Queremos ofrecerte facilidad a través de la tecnología. Es momento de hacer de tu oficina, un espacio inteligente." />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

const features = [
    {
        title: 'Administración de uso y optimización de cupos',
        description: 'Administra tus espacios de parqueadero para tener un mayor control y tomar decisiones basadas en reportes de uso en tiempo real para la correcta optimización de los espacios.',
        icon: 'fi-rr-search',
    },

    {
        title: 'Análisis de espacios',
        description: 'Podrás hacer un análisis de espacios desde nuestra plataforma que te ayudará a saber cuántos parqueaderos estás usando realmente, esto con el fin de no generar costos adicionales de parqueadero para tu empresa.',
        icon: 'fi-rr-stats',
    },
];

const extraFeatures = [
    {
        title: 'Reporte de uso de parqueaderos',
        description: 'Nuestra plataforma te permite obtener reportes del vehículo de preferencia, informes de horas y días de mayor ocupación de espacios de parqueadero, comparativos de métricas de uso y visualización en tiempo real.',
        icon: 'fi-rr-stats',
    },

    {
        title: 'Ingreso al parqueadero sin contacto',
        description: 'Desde la aplicación de Queo Access podrás entrar y salir con tu vehículo sin necesidad de tener contacto físico con superficies, además podrás evitar el uso de tarjetas compartidas.',
        icon: 'fi-rr-smartphone',
    },

    {
        title: 'Acceso VIP para tus visitantes',
        description: 'Nuestra plataforma te permite asignar un parqueadero para tus visitantes VIP, con el fin de que puedan ingresar a tu edificio u oficina de una forma fácil y sin esperas a través de una invitación previa.',
        icon: 'fi-rr-user',
    },
];

export default LandingParking;