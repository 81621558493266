import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import {
    HeroProduct,
    Intro,
    ProductFeatures,
    ProductVideo,
} from '../sections/LandingCommonSections';

const LandingWorkSpaces = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroProduct video="puestos" title="Reservas de puestos de trabajo" product="puestos colaborativos" customClass="workspaces" />
                <Intro intro="Con Queo Spaces podrás mantener la productividad y la salubridad de todos tus empleados ya que, a través de la aplicación de Queo Access, podrán reservar un puesto de trabajo colaborativo cuando sea necesario estar dentro de las instalaciones, manteniendo así una distancia segura."/>
                <ProductFeatures title="Puestos de trabajo rotativos" features={features} image="puestos" product="puestos colaborativos" />
                <ProductVideo video="puestos" title="Conoce cómo funciona" subtitle="Queremos ofrecerte facilidad a través de la tecnología. Es momento de hacer de tu oficina, un espacio inteligente." />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

const features = [
    {
        title: 'Identifica los puestos de trabajo disponibles',
        description: 'Contarás con un mapa en donde se visualizan todos los puestos de trabajo disponibles para que tus empleados puedan reservarlos y posteriormente acceder a ellos, manteniendo el distanciamiento.',
        icon: 'fi-rr-interactive',
    },

    {
        title: 'Facilidad para reservar',
        description: 'Tus colaboradores podrán reservar un puesto de trabajo de forma fácil desde nuestra app de Queo Access, allí podrán elegir el puesto que se encuentra disponible.',
        icon: 'fi-rr-smartphone',
    },

    {
        title: 'Reporte de reservas y uso de puestos de trabajo',
        description: 'A través de nuestra plataforma, podrás ver la ocupación de los puestos de trabajo de cada área de tu oficina. Esto te ayudará a tomar decisiones acertadas e informadas para el mejor funcionamiento y uso de las instalaciones.',
        icon: 'fi-rr-stats',
    },
];

export default LandingWorkSpaces;