import React from 'react';

const ProductFeature = (props) => {
    const {
        title,
        description,
        icon,
    } = props;

    return (
        <>
            <div className="queo-access-product-feature">
                <div className="feature-icon"><i className={icon}></i></div>
                <div className="feature-info-container">
                    <h3 className="feature-title">{title}</h3>
                    <p className="feature-description">{description}</p>
                </div>
            </div>
        </>
    );
};

export default ProductFeature;