import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import LandingSectionTitle from '../components/common/section-title';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const ProductFeatures = (props) => {

    const {
        bg,
        title,
        subtitle,
        video
    } = props;

    const videoPath = `${bucketURL}/media/landings/video/`;

    return (
        <>
            <section className={[
                'product-section-video',
                'height',
                'd-flex',
                'justify-content-center',
                'align-items-center',
                (!bg) ? '' : bg
            ].join(' ')}>
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col lg={6}>
                            <video controls className="product-video">
                                <source src={videoPath + video + '.webm'} type="video/webm" />
                                <source src={videoPath + video + '.mp4'} type="video/mp4" />
                            </video>
                        </Col>

                        <Col lg={6}>
                            <div className="product-video-description-wrapper">
                                <LandingSectionTitle
                                    text={title}
                                    alignment="center"
                                    theme="light"
                                />

                                <p className="product-video-subtitle">{subtitle}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default ProductFeatures;