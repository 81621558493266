import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const Intro = (props) => {

    const {
        intro
    } = props;

    return (
        <>
            <section className="product-section-intro height d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col md={12}>
                            <div className="intro-content-container">
                                <p className="product-intro" dangerouslySetInnerHTML={{__html: intro}} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Intro;