import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import Animation from '../components/animation/Animation';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Desktop, MobileTablet } from '../components/common/responsive-devices';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const Hero = () => {
    return (
        <>
            <section className="home-section-hero height d-flex align-items-center">
                <Container fluid>
                    <Row className="align-content-center justify-content-center">
                        <Col xs={12}>
                            <div className="hero-content-container">
                                <div className="hero-content-info">
                                    <h1 className="text-5xl medium text-orange">Descubre el poder de controlar tu infraestructura</h1>
                                    <p className="text-2xl subtitle-hero text-black">
                                        Somos una plataforma tecnológica que ayuda a conectar a tus colaboradores con los espacios de tu empresa, brindándoles una experiencia digital y haciendo de estos espacios lugares más eficientes.
                                    </p>
                                    <p className="text-2xl subtitle-hero text-black">
                                        Toma decisiones estratégicas de acuerdo al comportamiento real de tu equipo.
                                    </p>
                                    <Button href="/asesoria-gratis" block className="m-0 button bg-transparent rounded-pill queo-primary-btn light-theme hero-button">
                                        ¡Quiero más información!
                                    </Button>
                                </div>
                                <MobileTablet>
                                    <LazyLoadImage
                                        className="home-banner-illustration"
                                        alt="Queo Access - acceso a oficinas, soluciones para el control de espacios en empresas"
                                        src={`${bucketURL}/media/landings/images/ilustracion.png`} />
                                </MobileTablet>

                                <Desktop>
                                    <Animation />
                                </Desktop>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Hero;