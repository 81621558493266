import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <section className="wrapper-section footer">
            <Container>
                <Row className="justify-content-center aling-items-center">
                    <Col md={12} xs={12} className="justify-content-center">
                        <p className="text-center text">
                            <a className="footer-link" href="#que-somos">¿QUÉ SOMOS?</a> | 
                            <a className="footer-link" href="#planes">PLANES</a> | 
                            <a className="footer-link" href="#tecnologia">TECNOLOGÍA</a> | 
                            <a className="footer-link" href="#beneficios">BENEFICIOS</a> | 
                            <a className="footer-link" href="#descargar">DESCARGAR APP</a> | 
                            <a className="footer-link" href="#contacto">CONTACTO</a> | 
                        </p>

                        <p className="text-center text pb-2">
                            <a className="footer-link" href="/privacidad">CONOCE LAS POLÍTICAS DE PRIVACIDAD</a> |
                            <a className="footer-link" href="/privacidad"> VER LOS TÉRMINOS Y CONDICIONES</a>
                        </p>
                        <p className="text-center text">Queo Access - Powered by : Queo</p>
                        <p className="text-center text my-0">Queo Copyright {currentYear} | Todos los derechos reservados</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
if (typeof window !== 'undefined') {
    if(document.getElementById('footer')) {
        const container = document.getElementById('footer');
        const root = createRoot(container);
        root.render(<Footer />);
    }
}

export default Footer;