import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Mobile, Tablet, Desktop } from '../components/common/responsive-devices';
import LandingSectionTitle from '../components/common/section-title';
import Picture from '../components/common/Picture';
import ProductFeature from './ProductFeature';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const ProductFeatureHighlight = (props) => {

    const {
        title,
        imagePosition,
    } = props;

    return (
        <>
            <section className="product-section-highlight-feature height d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col xs={12}>
                            <LandingSectionTitle
                                text={title}
                                alignment="center"
                                theme="light"
                            />
                        </Col>
                    </Row>

                    {
                        imagePosition === 'left' &&
                        <Row className="my-5 align-content-center justify-content-center">
                            <HighlightContent {...props} />
                        </Row>
                    }

                    {
                        imagePosition === 'right' &&
                        <Row className="my-5 align-content-center flex-row-reverse justify-content-center">
                            <HighlightContent {...props} />
                        </Row>
                    }
                </Container>
            </section>
        </>
    );
};

const HighlightContent = (props) => {

    const {
        page,
        images,
        features,
        product,
    } = props;

    const imagesPath = `${bucketURL}/media/web/`;

    return (
        <>
            <Col lg={7} className="d-flex justify-content-center">
                <Mobile>
                    <LazyLoadImage
                        className="feature-highlight-image"
                        alt={product}
                        src={imagesPath + images?.mobile}/>
                </Mobile>

                <Tablet>
                    <LazyLoadImage
                        className="feature-highlight-image"
                        alt={product}
                        src={imagesPath + images?.tablet}/>
                </Tablet>

                <Desktop>
                    <LazyLoadImage
                        className="feature-highlight-image"
                        alt={product}
                        src={imagesPath + images?.desktop}/>
                </Desktop>
            </Col>

            <Col lg={5}>
                <Row>
                    <Col xs={12}>
                        {page === 'access' &&
                            <HighlightAccess />
                        }

                        {page === 'parking' &&
                            <HighlightParking features={features} />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-center">
                        <Button href={product ? `/asesoria-gratis?product=${product}` : '/asesoria-gratis'} block className="mt-3 button orange-button rounded-pill mx-auto try-btn-highlight-feature">
                            ¡Quiero más información!
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

const HighlightAccess = () => {
    return (
        <>
            <div className="queo-access-product-feature-highlight">
                <Picture
                    pathName="landing/svg/medical-history.svg"
                    className="access-highlight-icon"
                    alt="Formulario de salubridad"
                    pictureProps={{ style: { width: '70px', margin: 'auto', marginBottom: '15px' } }}
                />
                <p>
                    <strong>Queo Access</strong> cuenta con un formulario de salud para registrar la información de cada uno de los usuarios. Esto te permitirá dejar fuera de tus instalaciones cualquier enfermedad y mantener la bioseguridad de la copropiedad.
                </p>
                <p>
                    Esta información será completamente confidencial.
                </p>
            </div>
        </>
    );
};

const HighlightParking = (props) => {
    const {
        features
    } = props;
    return (
        <>
            {features.map((feature, i) =>
                <ProductFeature key={i} {...feature} />
            )}
        </>
    );
};

export default ProductFeatureHighlight;