import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Mobile, Default } from '../components/common/responsive-devices';

const HeroFreeTrial = () => {
    return (
        <>
            <section className="free-trial-section-hero height" id="contacto">
                <Container>
                    <Row className="align-items-center align-items-lg-start">
                        <Col xs={12} lg={8}>
                            <Mobile>
                                <h1 className="free-trial-title text-3xl extrabold">
                                    ¿No sabes cómo comenzar tu reactivación? <br/>¡Solicita tu asesoría gratis!
                                </h1>
                            </Mobile>
                            <Default>
                                <h1 className="free-trial-title text-5xl extrabold">
                                    ¿No sabes cómo comenzar tu reactivación? ¡Solicita tu asesoría gratis!
                                </h1>
                            </Default>
                        </Col>
                    </Row>
                    <Row className="align-items-center align-items-lg-start">
                        <Col xs={12}>
                            <p className="free-trial-description my-5">
                                Solicita tu asesoría con nuestro <strong>equipo de expertos</strong>, quienes te darán un plan de trabajo para reactivar tu oficina o edificio y mantener tu lugar de trabajo <strong>seguro, saludable y productivo</strong>.
                            </p>
                        </Col>

                        <Col xs={12}>
                            <div class="powr-form-builder" id="277d12a0_1695308252"></div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HeroFreeTrial;