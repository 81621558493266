import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import {
    HeroProduct,
    Intro,
    ProductFeatures,
    ProductVideo,
} from '../sections/LandingCommonSections';

const LandingRooms = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroProduct video="salas" title="Reservas de salas de conferencias" product="salas de conferencias" customClass="rooms" />
                <Intro intro="Con Queo Spaces podrás mantener la productividad y salubridad de todos tus empleados. A través de la aplicación de Queo Access podrás reservar la sala que necesites, además podrás permitirle a los asistentes a la reunión el acceso a esta. En las salas encontrarás indicadores verdes para las disponibles y rojas para las ocupadas."/>
                <ProductFeatures 
                    title="Reserva de salas de conferencia" 
                    features={features} image="salas" 
                    product="salas de conferencias"
                />
                <ProductVideo video="salas" title="Conoce cómo funciona" subtitle="Queremos ofrecerte facilidad a través de la tecnología. Es momento de hacer de tu oficina, un espacio inteligente." />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

const features = [
    {
        title: 'Programación de próximas reuniones',
        description: 'Con Queo Access podrás ver claramente las próximas horas, tanto disponibles como ocupadas, de las salas de reuniones para realizar tus reservas.',
        icon: 'fi-rr-time-quarter-past',
    },

    {
        title: 'Controles de privacidad y reserva',
        description: 'Podrás decidir y asignar qué empleados pueden reservar las salas con el fin de controlar el acceso a estas y mantener un control de los espacios en tus instalaciones.',
        icon: 'fi-rr-lock',
    },

    {
        title: 'Libera el espacio si ya no lo necesitas',
        description: 'Podrás liberar rápidamente el espacio si la reunión se cancela o si ya no necesitas la sala. De esta manera, otros empleados podrán usarla en caso de ser necesario.',
        icon: 'fi-rr-unlock',
    },

    {
        title: 'Toma decisiones basándote en datos reales',
        description: 'Con Queo Access contarás con diversos análisis de comportamiento y uso de salas de reuniones para tomar las decisiones más acertadas e informadas sobre el uso de los espacios.',
        icon: 'fi-rr-stats',
    },
];

export default LandingRooms;