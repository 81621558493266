import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import { Row, Container, Col } from 'react-bootstrap';

const LandingPrivacy = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <section className="privacy-section">
                    <Container>
                        <Row className="align-items-center align-items-lg-start">
                            <Col md={12}>
                                <h2 className="jsx-1632496189 queo-title-h2">POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE DATOS
                                    PERSONALES DE QUEO S.A.S.</h2>
                                <p className="jsx-1632496189 queo-font-terms">www.queo.com.co</p>
                                <p className="jsx-1632496189 queo-font-terms">aviso</p>
                                <p className="jsx-1632496189 text-justify queo-font-terms">La presente Política de Privacidad y
                                    Tratamiento de Datos Personales de QUEO (en adelante la "Política"),
                                    establece los mecanismos, garantías e instrumentos que los titulares de la información que
                                    recolectamos y
                                    tratamos en calidad de Responsables del Tratamiento a la luz del Marco Normativo (según este
                                    se define en la Sección 1 de esta Política).
                                    La presente Política está organizada según el Índice (según se indica más adelante), con el
                                    fin de establecer las condiciones generales
                                    aplicables al tratamiento de datos personales que realiza QUEO, así como aquellas
                                    disposiciones aplicables
                                    para los diferentes titulares de dicha información que entablan relaciones comerciales y
                                    jurídicas con QUEO, o frente a las cuales QUEO
                                    accede a información de carácter personal.</p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 1. Marco Normativo </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 2. Definiciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.1. Ley 1266 de 2008 – Definiciones
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.2. Ley 1581 de 2012 – Definiciones
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.3. Decreto 1377 de 2013 –
                                    Definiciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 3. Principios que orientan la
                                    Política </p>
                                <p className="jsx-1632496189 queo-font-terms"> contenido </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 4. Alcance de la Política
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 5. Derechos de los Titulares
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 6. Deberes de QUEO </p>
                                <p className="jsx-1632496189 queo-font-terms"> 6.1. Deber General de QUEO en el Tratamiento
                                    de Información </p>
                                <p className="jsx-1632496189 queo-font-terms"> 6.2. Deberes Especiales de QUEO en su calidad
                                    de Responsable del Tratamiento de Datos Personales y Datos Personales Crediticios
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 7. Autorizaciones, consentimientos,
                                    finalidad y modificaciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.1. Autorización y consentimiento
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.2. Finalidades </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.3. Modificaciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 8. Seguridad de la
                                    Información </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 9. Datos e Información
                                    Sensible </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 10. Utilización y Transferencia
                                    Internacional de Información Protegida </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 11. Atención de Reclamos y
                                    Consultas </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 12. Información del Responsable del
                                    Tratamiento </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 13. Anexos </p>
                            </Col>
                            <Col md={12}>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 1. Marco Normativo </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 2. Definiciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.1. Ley 1266 de 2008 – Definiciones
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.2. Ley 1581 de 2012 – Definiciones
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 2.3. Decreto 1377 de 2013 –
                                    Definiciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 3. Principios que orientan la
                                    Política </p>
                                <p className="jsx-1632496189 queo-font-terms"> contenido </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 4. Alcance de la Política
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 5. Derechos de los Titulares
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 6. Deberes de QUEO </p>
                                <p className="jsx-1632496189 queo-font-terms"> 6.1. Deber General de QUEO en el Tratamiento
                                    de Información </p>
                                <p className="jsx-1632496189 queo-font-terms"> 6.2. Deberes Especiales de QUEO en su calidad
                                    de Responsable del Tratamiento de Datos Personales y Datos Personales Crediticios
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 7. Autorizaciones, consentimientos,
                                    finalidad y modificaciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.1. Autorización y consentimiento
                                </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.2. Finalidades </p>
                                <p className="jsx-1632496189 queo-font-terms"> 7.3. Modificaciones </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 8. Seguridad de la
                                    Información </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 9. Datos e Información
                                    Sensible </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 10. Utilización y Transferencia
                                    Internacional de Información Protegida </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 11. Atención de Reclamos y
                                    Consultas </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 12. Información del Responsable del
                                    Tratamiento </p>
                                <p className="jsx-1632496189 queo-font-terms"> Sección 13. Anexos </p>
                            </Col>
                            <Col md={12}>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 1. Marco Normativo. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> El marco legal y
                                        constitucional (en adelante el "Marco Normativo") bajo el cual se rige la Política es el
                                        siguiente: Constitución Política de Colombia, Artículo 15.
                                        Ley 1266 de 2008. Ley 1581 de 2012. Decretos Reglamentarios 1727 de 2009, 2952 de 2010,
                                        1377 de 2013.
                                        Sentencias de la Corte Constitucional: C-1011 de 2008 y C-748 del 2011. Todas aquellas
                                        normas que modifiquen o adicionen las leyes y decretos aquí listados</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 2. Definiciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Para los efectos de la
                                        Política, se tendrán como definiciones de los términos utilizados, aquellas definiciones
                                        incluidas en el Marco Normativo, en especial las Leyes 1266 de 2008 y 1581 de 2012 y el
                                        Decreto 1377 de 2013, de las cuales se incluyen las utilizadas expresamente en esta
                                        Política, de la siguiente forma:</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.1. Ley 1266 de 2008 –
                                        Definiciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 1.Dato Personal: Es
                                        cualquier pieza de información vinculada a una o varias personas determinadas o
                                        determinables o que puedan asociarse con una persona natural o jurídica. Los datos
                                        impersonales no se sujetan al régimen de protección de datos de la Ley 1266 de 2008.
                                        Cuando en la Ley 1266 de 2008 se hace referencia a un dato, se presume que se trata de
                                        uso personal. Los datos personales pueden ser públicos, semiprivados o privados. Para
                                        efectos de estas Políticas, los Datos Personales definidos en la Ley 1266 de 2008 se
                                        denominarán "Dato Personal Crediticio", en el entendido que incorporan la Información
                                        financiera, crediticia, comercial, de servicios y la proveniente de terceros países, con
                                        el alcance dado por la Corte Constitucional mediante Sentencia C-748 de 2011. En
                                        consecuencia, todo Dato Personal Crediticio será exclusivamente cualquier información
                                        financiera o comercial destinada a calcular el nivel de riesgo crediticio del Titular
                                        (Como se define en la Sección 3)</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 2.Dato Semiprivado: Es
                                        semiprivado el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo
                                        conocimiento o divulgación puede interesar no sólo a su Titular sino a cierto sector o
                                        grupo de personas o a la sociedad en general, como el dato financiero y crediticio de
                                        actividad comercial o de servicios a que se refiere el Título IV de la Ley 1266 de 2008.
                                    </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 3.Dato Privado: Es el
                                        dato que por su naturaleza íntima o reservada sólo es relevante para el Titular.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 4.Información
                                        financiera, crediticia, comercial, de servicios y la proveniente de terceros países:
                                        Para todos los efectos de la Ley 1266 de 2008, y de la Política, se entenderá por
                                        información financiera, crediticia, comercial, de servicios y la proveniente de terceros
                                        países, aquella referida al nacimiento, ejecución y extinción de obligaciones
                                        dinerarias, independientemente de la naturaleza del contrato que les dé origen.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.2Ley 1581 de 2012 –
                                        Definiciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 1.Dato Personal:
                                        Cualquier información vinculada o que pueda asociarse a una o varias personas naturales
                                        determinadas o determinables. Para efectos de esta Política, cuando se haga referencia
                                        al término "Datos Personales", se entenderá exclusivamente realizada a los datos
                                        personales definidos en la Ley 1581 de 2012, con el alcance otorgada por esta
                                        normatividad.
                                        2.Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
                                        misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del
                                        Responsable del Tratamiento.
                                        3.Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
                                        misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los
                                        datos.
                                        4.Tratamiento: Cualquier operación o conjunto de operaciones sobre Datos Personales (Ley
                                        1581 de 2012), tales como la recolección, almacenamiento, uso, circulación o supresión.
                                        Para efectos de esta Política, se entenderá que el Tratamiento abarca también la
                                        recolección, captura, almacenamiento, uso, circulación, transmisión, transferencia o
                                        supresión de "Información Protegida", según se define este término en la Sección 3 del
                                        presente documento.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.3. Decreto 1377 de 2013 –
                                        Definiciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 1.Datos Sensibles: Se
                                        entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso
                                        indebido puede generar su discriminación, tales como aquellos que revelen el origen
                                        racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la
                                        pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva
                                        intereses de cualquier partido político o que garanticen los derechos y garantías de
                                        partidos políticos de oposición, así como los datos relativos a la salud, a la vida
                                        sexual, y los datos biométricos.
                                        2.Transferencia: La transferencia de datos tiene lugar cuando el Responsable del
                                        Tratamiento y/o el Encargado del Tratamiento de Datos Personales, ubicado en la
                                        República de Colombia, envía la información o los Datos Personales a un receptor, que a
                                        su vez es Responsable del Tratamiento y se encuentra dentro o fuera de la República de
                                        Colombia.
                                        3.Transmisión: Tratamiento de Datos Personales que implica la comunicación de los mismos
                                        dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la
                                        realización de un Tratamiento por el Encargado del Tratamiento por cuenta del
                                        Responsable del Tratamiento.
                                        Las anteriores definiciones establecidas por las leyes 1266 de 2008 y 1581 de 2012 y el
                                        Decreto 1377 de 2013 serán aplicables y tendrán el alcance que en cada norma se les
                                        otorga; en consecuencia, para efectos de su interpretación y aplicación dentro la
                                        Política, es necesario tomar en consideración el sentido y ámbito específico de cada
                                        concepto dentro de la norma correspondiente. El Marco Normativo forma parte integral de
                                        la Política, y en especial, las Leyes 1266 de 2008 y 1581 de 2012, junto con el Decreto
                                        1377 de 2013.
                                        Los demás términos definidos en el presente documento, tendrán el significado que aquí
                                        se les asigna</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 3. Principios que orientan la
                                        Política. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO y sus sociedades
                                        afiliadas por vínculos de subordinación o control en Colombia o en una jurisdicción o
                                        territorio diferente (en adelante las "Sociedades Vinculadas"), en el Tratamiento de la
                                        información recolectada y accedida de sus clientes, usuarios, empleados, proveedores,
                                        subcontratistas y cualquier otro tercero (en adelante los "Titulares"), en el desarrollo
                                        y giro de sus negocios y actividades en Colombia, sea que se trate dicha información de
                                        Datos Personales y/o Datos Personales Crediticios (en adelante la "Información
                                        Protegida"), respeta y respetará los derechos de cada uno de estos sujetos, aplicando y
                                        garantizando los siguientes principios orientadores de la Política:</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Legalidad:
                                        En el Tratamiento de la Información Protegida, se dará aplicación a las disposiciones
                                        vigentes y aplicables, que rigen el Tratamiento de la misma y demás derechos
                                        fundamentales conexos, incluyendo las disposiciones contractuales pactadas por la
                                        Compañía con los Titulares, según corresponda.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Libertad:
                                        El Tratamiento de Datos Personales y Datos Personales Crediticios, sólo se llevará a
                                        cabo con el consentimiento, previo, expreso e informado del Titular. Los Datos
                                        Personales y Datos Personales Crediticios, que no tengan el carácter de Datos Públicos,
                                        no podrán ser obtenidos o divulgados sin previa autorización, o en ausencia de mandato
                                        legal, estatutario, o judicial que releve el consentimiento. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Finalidad:
                                        El Tratamiento de la Información Protegida que no tenga el carácter de Dato Público, a
                                        los que tenga acceso y sean acopiados y recogidos por la Compañía, estarán subordinados
                                        y atenderán una finalidad legítima, la cual será informada al respectivo Titular de la
                                        Información Protegida.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Veracidad
                                        o Calidad: La Información Protegida sujeta a Tratamiento debe ser veraz, completa,
                                        exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos
                                        parciales, incompletos, fraccionados o que induzcan a error. La Compañía no será
                                        responsable frente al Titular cuando sean objeto de Tratamiento, datos o información
                                        parcial, incompleta o fraccionada o que induzca a error, entregada por el Titular sin
                                        que existiera la forma de ser verificada la veracidad o calidad de la misma por parte de
                                        la Compañía o la misma hubiere sido entregada o divulgada por el Titular declarando o
                                        garantizando, de cualquier forma, su veracidad o calidad.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de
                                        Transparencia: En el Tratamiento de Información Protegida, se garantizará el derecho del
                                        Titular a obtener de la Compañía, en cualquier momento y sin restricciones, información
                                        acerca de la existencia de cualquier tipo de Información Protegida que sea de su interés
                                        (legal, judicial o contractualmente justificado) o titularidad.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Acceso y
                                        Circulación Restringida: La Información Protegida no estará disponible en Internet u
                                        otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente
                                        controlable para brindar un conocimiento restringido sólo a la Compañía, las Sociedades
                                        Vinculadas, los Titulares o terceros debidamente autorizados. Para estos propósitos la
                                        obligación de la Compañía, será de medio, según lo establece la normatividad vigente.
                                    </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de Seguridad:
                                        La Información Protegida bajo la Política sujeta a Tratamiento por la Compañía, será
                                        objeto de protección en la medida en que los recursos técnicos y estándares mínimos así
                                        lo permitan, a través de la adopción de medidas tecnológicas de protección, protocolos,
                                        y medidas administrativas que sean necesarias para otorgar seguridad a los registros y
                                        repositorios electrónicos evitando su adulteración, modificación, pérdida, consulta, y
                                        en general en contra de cualquier uso o acceso no autorizado.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de
                                        Confidencialidad: Todas y cada una de las personas que en la Compañía administran,
                                        manejan, actualizan o tienen acceso a Información Protegida que no tenga el carácter de
                                        pública, y se encuentre en Sistemas de Información o bases o bancos de datos de terceros
                                        debidamente autorizados, se comprometen a conservar y mantener de manera estrictamente
                                        confidencial y no revelar a terceros todas o cualesquiera de las informaciones
                                        personales, comerciales, contables, técnicas, comerciales o de cualquier otro tipo
                                        suministradas en la ejecución y ejercicio de sus funciones.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> La Compañía y sus
                                        Sociedades Vinculadas para el Tratamiento de la Información Protegida podrán utilizar
                                        los sistemas de información y bases de datos de propiedad de la Compañía y/o de sus
                                        Sociedades Vinculadas (en adelante los "Sistemas de Información").</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Principio de
                                        Temporalidad de la Información: En el caso de los Datos Personales Crediticios, los
                                        mismos no serán suministrados a usuarios o terceros cuando dejen de servir para la
                                        finalidad del banco de datos correspondiente.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 4. Alcance de la
                                        Política. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> La presente Política
                                        tendrán el mismo ámbito de aplicación consagrado en las normas legales que forman parte
                                        del Marco Normativo, y se extenderán para regular y consagrar las políticas generales e
                                        integrales de Tratamiento de Información Protegida por parte de la Compañía. En
                                        consecuencia, serán aplicables para todas las operaciones desarrolladas por la Compañía
                                        en Colombia, salvaguardando el eventual uso o Tratamiento que de la Información
                                        Protegida realicen, en cumplimiento de los requisitos legales, las Sociedades
                                        Vinculadas. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> En el evento que
                                        cualquiera de las Sociedades Vinculadas tenga acceso o realice el Tratamiento legítimo y
                                        autorizado de Información Protegida de los Titulares, sin perjuicio de lo establecido en
                                        la Sección X, se garantizará lo establecido la Sección 3 y 4 según les sea aplicable.
                                        Frente a los Titulares, la Compañía será el único responsable de garantizar el
                                        cumplimiento de estas Políticas por parte de sus Sociedades Vinculadas. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Sin perjuicio del
                                        alcance general indicado en esta Sección 4, frente a cada uno de los Titulares de
                                        Información Protegida, en el Anexo A de esta Política definidos en la Sección 13, la
                                        Compañía ha establecido un alcance y campo de aplicación específico, con términos y
                                        condiciones predicables y aplicables para cada Titular, según su calidad o carácter.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 5. Derechos de los
                                        Titulares. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Los Titulares tendrán
                                        los derechos consagrados en el Marco Normativo y en los contratos celebrados con QUEO,
                                        según les sean aplicables teniendo en consideración la Información Protegida objeto de
                                        Tratamiento por parte de QUEO y/o de las Sociedades Vinculadas. Los Titulares de los
                                        cuales se realice el Tratamiento de Datos Personales tendrán específicamente los
                                        derechos previstos por la Ley 1581 de 2012, concretamente, pero sin limitarse a los
                                        descritos en el Artículo 8, y todas aquellas normas que la reglamenten, adicionen o
                                        complementen. Los Titulares de los cuales se realice el Tratamiento de Datos Personales
                                        Crediticios, en caso que QUEO recolecte dichos datos, tendrán específicamente los
                                        derechos previstos por la Ley 1266 de 2008, concretamente, pero sin limitarse a los
                                        descritos en el Artículo 6, y todas aquellas normas que la reglamenten, adicionen o
                                        complementen. Para efectos de interpretación de las leyes y decretos que se expidan en
                                        la materia, se tendrá como parámetro de interpretación legal y constitucional las
                                        sentencias de la Corte Constitucional de Colombia. Los derechos de los Titulares se
                                        interpretarán en armonía y en un plano de equilibrio con el derecho a la información
                                        previsto en el Artículo 20 de la Constitución Política de Colombia y con los demás
                                        derechos constitucionales aplicables. Para toda aquella Información Protegida, que sea
                                        recolectada por QUEO y/o las Sociedades Vinculadas, y que no tenga el carácter de: (i)
                                        Datos Personales de carácter privado o semiprivado; y (ii) Datos Personales Crediticios
                                        de carácter privado o semiprivado, los derechos de los Titulares serán únicamente
                                        aquellos establecidos y pactados de manera contractual con QUEO. En todo caso frente a
                                        esta información, QUEO garantizará la seguridad y confidencialidad de la misma cuando
                                        esté sujeta a ello y los principios orientadores de las Políticas que les sean
                                        aplicables. Tratándose de información o datos de carácter público, QUEO garantizará la
                                        veracidad y calidad de la información que ostenta esta calidad y que repose en los
                                        Sistemas de Información.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 6. Deberes de QUEO. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 6.1. Deber General de
                                        QUEO en el Tratamiento de Información.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO de manera general,
                                        y de conformidad con el Marco Normativo, las normas legales que regulan sus relaciones
                                        jurídicas con los Titulares, y en especial, las obligaciones específicas que asume
                                        frente a los Titulares, tiene como deber general en el Tratamiento de Información
                                        Protegida el respetar y garantizar en todo momento los derechos propios de los
                                        Titulares, garantizando, cuando sea aplicable y según el carácter de la información
                                        utilizada, la confidencialidad, reserva, seguridad e integridad de la misma.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 6.2. Deberes Especiales
                                        de QUEO en su calidad de Responsable del Tratamiento de Datos Personales y Datos
                                        Personales Crediticios.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 6.2.1. Garantizar al
                                        Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data.
                                        6.2.2. Solicitar y conservar, copia de la respectiva autorización otorgada por el
                                        Titular, cuando se traten de Datos Personales y Datos Personales Crediticios privados o
                                        semiprivados. 6.2.3. Informar debidamente al Titular sobre la finalidad de la
                                        recolección y los derechos que le asisten por virtud de la autorización otorgada. 6.2.4.
                                        Conservar la información bajo las condiciones de seguridad necesarias para impedir su
                                        adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento. 6.2.5.
                                        Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz,
                                        completa, exacta, actualizada, comprobable y comprensible. 6.2.6. Actualizar la
                                        información, comunicando de forma oportuna al Encargado del Tratamiento, todas las
                                        novedades respecto de los datos que previamente le haya suministrado y adoptar las demás
                                        medidas necesarias para que la información suministrada a este se mantenga actualizada.
                                        6.2.7. Rectificar la información cuando sea incorrecta y comunicar lo pertinente al
                                        Encargado del Tratamiento. 6.2.8. Suministrar al Encargado del Tratamiento, según el
                                        caso, únicamente datos cuyo Tratamiento esté previamente autorizado. 6.2.9. Exigir al
                                        Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y
                                        privacidad de la información del Titular. 6.2.10. Tramitar las consultas y reclamos
                                        formulados. 6.2.11. Informar al Encargado del Tratamiento cuando determinada información
                                        se encuentra en discusión por parte del Titular, una vez se haya presentado la
                                        reclamación y no haya finalizado el trámite respectivo. 6.2.12. Informar a solicitud del
                                        Titular sobre el uso dado a sus datos. 6.2.13. Informar a la autoridad de protección de
                                        datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la
                                        administración de la información de los Titulares.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 7. Autorizaciones,
                                        consentimientos, finalidad y modificaciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> 7.1. Autorización y
                                        consentimiento.</p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> El Tratamiento de Datos
                                        Personales y Datos Personales Crediticios privados o semiprivados, por parte de QUEO y/o
                                        de las Sociedades Vinculadas, requiere del consentimiento libre, previo, expreso e
                                        informado del Titular. QUEO en todo momento dejará constancia de la autorización
                                        entregada por el Titular, a través de los medios idóneos que garanticen que la misma fue
                                        otorgada de manera expresa, libre, previa e informada, como lo son las autorizaciones
                                        por escrito, en medios electrónicos u otorgadas al aceptar términos y condiciones de
                                        servicios y/o productos, el aviso de privacidad correspondiente que soporta el
                                        Tratamiento de los Datos Personales o cualquier otro mecanismo que permite acreditar y
                                        demostrar un registro, acceso o vinculación a servicios y/o productos de QUEO.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 7.2. Finalidades. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Los fines y propósitos
                                        del Tratamiento de los Datos Personales y Datos Personales Crediticios privados o
                                        semiprivados, siempre estarán de manera clara y expresa, establecidos en aquellos medios
                                        idóneos, a través de los cuales QUEO requiera la autorización de los Titulares. QUEO no
                                        utilizará los Datos Personales y Datos Personales Crediticios privados o semiprivados
                                        para fines distintos de los expresamente autorizados por los Titulares. En el Anexo A de
                                        esta Política, se establecen algunos de los Datos Personales y Datos Personales
                                        Crediticios que podrá recolectar QUEO, estableciendo las finalidades del Tratamiento de
                                        los mismos, con el propósito que los Titulares estén informados en todo momento sobre el
                                        uso que de los mismos adelantará, o podrá adelantar, QUEO y las Sociedades Vinculadas.
                                    </p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 7.3. Modificaciones. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Esta Política podrá ser
                                        modificada y adicionada de tiempo en tiempo, en cumplimiento de requerimientos
                                        establecidos por las normas legales correspondientes. La Política debidamente
                                        actualizada, indicando la fecha de actualización, estará publicada oportunamente en el
                                        Portal Web de QUEO y las Aplicaciones Móviles de QUEO o de las Sociedades Vinculadas
                                        (según el Portal Web y las Aplicaciones Móviles se definen en el Anexo A de esta
                                        Política) y podrá ser informada mediante correo electrónico. El Titular reconoce y
                                        acepta que con el uso del Portal Web y/o las Aplicaciones Móviles, está de acuerdo con
                                        esta Política, implicando todo uso continuo de las mismas la aceptación inequívoca de la
                                        misma. QUEO, de conformidad con el Marco Normativo, no estará obligado a suprimir de sus
                                        Sistemas de Información la Información Protegida, frente a la cual el Titular tenga un
                                        deber legal o contractual con QUEO y/o las Sociedades Vinculadas de permanecer en los
                                        Sistemas de Información.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 8. Seguridad de la
                                        Información. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> En desarrollo del
                                        Principio de Seguridad establecido en la normatividad vigente y de conformidad con las
                                        obligaciones legales que QUEO posee, la Compañía adoptará las medidas tecnológicas,
                                        operativas y administrativas que sean necesarias para otorgar seguridad a los registros
                                        e Información Protegida de los Titulares, evitando su adulteración, pérdida, consulta,
                                        uso o acceso no autorizado o fraudulento.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 9. Datos e Información
                                        Sensible. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Sin perjuicio de las
                                        disposiciones específicas que se puedan contemplar en el Anexo A de esta Política, QUEO
                                        manifiesta a todos los Titulares que en el evento que dentro del ejercicio y desarrollo
                                        de sus actividades solicite el Tratamiento de Datos Sensibles, o realice efectivamente
                                        el Tratamiento de Datos Sensibles para la correcta operación o funcionamiento de los
                                        diferentes procesos y actividades de su empresa, dará cumplimiento a las pautas y
                                        restricciones que para tales propósitos establece el Marco Normativo, reiterando que en
                                        ningún momento los Titulares estarán obligados a autorizar el Tratamiento de Datos
                                        Sensibles. Todo Titular deberá consentir expresamente a QUEO y las Sociedades Vinculadas
                                        el Tratamiento de Datos Sensibles bajo la presente Política. En el Anexo A se establecen
                                        las condiciones específicas relativas a Datos Sensibles de niños, niñas y adolescentes
                                        menores de edad (los "Menores de Edad"), y en especial frente a las restricciones y
                                        limitaciones establecidas por QUEO frente a la contratación y uso de sus servicios y/o
                                        productos por parte de Menores de Edad.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 10. Utilización y Transferencia
                                        Internacional de Información Protegida. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> En el evento que QUEO,
                                        en el ejercicio de sus actividades propias, utilice, transmita o transfiera
                                        internacionalmente Información Protegida garantizará el cumplimiento de los principios
                                        aplicables establecidos en la Sección 3 de la presente Política. Cuando se trate de la
                                        transferencia de Datos Personales, QUEO dará cumplimiento a lo dispuesto en la Ley 1581
                                        de 2012, en especial el Artículo 26, y demás normas que lo modifiquen, adicionen o
                                        complementen. Concretamente QUEO, en el giro ordinario de sus negocios podrá incorporar
                                        la Información Protegida dentro de los Sistemas de Información. QUEO, como Responsable
                                        del Tratamiento garantiza que los Sistemas de Información cumplen en su integridad las
                                        Políticas y el Marco Normativo, y en consecuencia, garantiza que cualquier Titular
                                        podrá: (i) conocer en cualquier momento la información que reposa en los Sistemas de
                                        Información; (ii) solicitar la actualización o rectificación de los datos allí
                                        incorporados; y (iii) solicitar, salvo en aquellos eventos previstos en la Sección 7
                                        (Numeral 7.3) de la Política, la supresión de sus datos notificando a QUEO, para lo cual
                                        se seguirá el procedimiento establecido en la Sección 11 del presente documento. El
                                        Titular reconoce con la aceptación de la Política que, de presentarse una venta, fusión,
                                        consolidación, cambio de control societario, transferencia sustancial de activos,
                                        reorganización o liquidación de la Compañía, la Compañía podrá transferir, enajenar o
                                        asignar la Información Protegida a una o más partes relevantes, incluidas Sociedades
                                        Vinculadas.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 11. Atención de Reclamos y
                                        Consultas. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> El Titular o sus
                                        causahabientes que consideren que la Información Protegida contenida en un Sistema de
                                        Información, o en una base de datos, debe ser objeto de corrección, actualización o
                                        supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes
                                        contenidos en el Marco Normativo, podrán presentar un reclamo o solicitud ante QUEO,
                                        remitiéndola en una primera instancia a través del siguiente correo electrónico
                                        habilitado para el efecto por QUEO: protecciondedatos@queo.com.co (en adelante el
                                        "Correo Electrónico Autorizado"). Para efectos de proceder a cualquier solicitud, es
                                        importante que el Titular diligencie de manera completa el Formulario de Reclamos y
                                        Consultas (en adelante el "Formulario"), que se encuentra disponible en las oficinas de
                                        QUEO o en el sitio web de QUEO, y lo envíe escaneado al Correo Electrónico Autorizado o
                                        en físico a las oficinas de QUEO en la siguiente dirección: Calle 131ª No. 9 - 59, a la
                                        atención del Departamento de Servicio al Cliente. QUEO atenderá y dará respuesta a los
                                        reclamos o solicitudes de los Titulares en los plazos y términos establecidos para el
                                        efecto por el Marco Normativo. El Titular, sin perjuicio de lo anterior, y en el evento
                                        que no haya sido atendida su solicitud o reclamo por parte de QUEO, podrá en todo caso
                                        acudir posteriormente en una segunda instancia, ante la Superintendencia de Industria y
                                        Comercio (www.sic.gov.co). En dicho evento, para la presentación del reclamo ante la
                                        Superintendencia de Industria y Comercio se tomará en consideración la naturaleza de la
                                        Información Protegida, siendo procedente cuando dicha información: (i) no tenga el
                                        carácter de información pública o sea un Dato Público, y (ii) QUEO esté en violación de
                                        los principios aplicables para la información pública o de un Dato Público.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 12. Información del Responsable
                                        del Tratamiento. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Para todos los efectos
                                        legales el Responsable del Tratamiento de la Información Protegida será: QUEO S.A.S.
                                        (QUEO) NIT. 900.986.779-4 Teléfono: (+57) 320 4761366. Dirección: Cra 10 #96-79 Of 404,
                                        Bogotá D.C., Colombia. Área Responsable: Departamento de Servicio al Cliente Correo
                                        Electrónico Autorizado: protecciondedatos@queo.com.co Página web: www.queo.com.co</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Sección 13. Anexos. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Con el fin de
                                        contemplar aspectos específicos de la protección de Información Protegida de Titulares o
                                        regular aspectos concretos aplicables a las Sociedades Vinculadas que podrán ser
                                        requeridos por autoridades competentes o por la ley aplicable en la jurisdicción donde
                                        las Sociedades Vinculadas se encuentra incorporadas o constituidas, la Compañía
                                        establecerá dichas estipulaciones y condiciones específicas en Anexos a esta Política.
                                    </p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Versión: </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Fecha:26-01-2018
                                        Versión: 1.0</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Anexo A </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Capítulo 1.
                                        Disposiciones aplicables para usuarios o clientes y visitantes registrados (los
                                        "Usuarios") al sitio web: www.queo.com.co (el "Portal") y/o las aplicaciones móviles de
                                        QUEO (las "Plataformas Móviles", y en conjunto con el "Portal", en adelante las
                                        "Plataformas").</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.1. Protegiéndolo. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO está comprometido
                                        en proteger su privacidad y sus datos personales. En este sentido, en este Capítulo 1,
                                        se describe además de la Información Protegida que QUEO recolecta de los Usuarios a
                                        través de las Plataformas y los fines del Tratamiento de dicha información, los medios y
                                        canales a través de los cuales obtenemos su consentimiento para dicho tratamiento,
                                        anotando que QUEO nunca recolectará Información Protegida sin su consentimiento. A
                                        través de la Política y de este Anexo A, aseguramos que cualquier Información Protegida
                                        que sea entrega o recolectada de los Usuarios, una vez completado el proceso de registro
                                        en las Plataformas, será mantenida en forma segura y reservada. QUEO se permite
                                        aclararles y precisarles a todos los visitantes a las Plataformas, que no podrán
                                        registrarse en las mismas hasta tanto no otorguen su autorización para el Tratamiento de
                                        Información Protegida. En este sentido, nuestras Plataformas exigirán que nos brinde su
                                        consentimiento previo, expreso e informado seleccionando la casilla que dispone: “He
                                        leído y estoy de acuerdo con los términos y condiciones y con las Políticas de
                                        Privacidad y Tratamiento de Datos Personales”, o con el texto similar incluido en la
                                        correspondiente Plataforma. En caso que no seleccione dicha casilla antes de dar “click”
                                        en el botón de “REGISTRARME”, su Información Protegida NO será almacenada en los
                                        Sistemas de Información. Igualmente, les informamos que cada vez que realice un pedido a
                                        través de las Plataformas, se generará una nueva autorización.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.2. Información (incluida Información
                                        Protegida) que recolectamos y recabamos. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Con el registro en
                                        nuestras Plataformas, recolectamos y recabamos información de identificación personal
                                        (IP), la cual incluye, pero sin limitarse, el: correo electrónico, celular, teléfono,
                                        dirección de residencia, georreferenciación e información de los medios de pago
                                        utilizados (tarjeta de crédito). Esta información es cedida voluntariamente durante el
                                        proceso de registro o en respuesta a requerimientos explícitos presentados por QUEO.
                                        También podemos recabar su dirección de IP (Internet Protocol) para ayudar a
                                        diagnosticar problemas con nuestro servidor, y para administrar las Plataformas. Una
                                        dirección de IP es un número que se le asigna al dispositivo por medio del cual se
                                        accede a las Plataformas. Su dirección de IP también es utilizada para ayudar a
                                        identificar a nuestros Usuarios dentro de una sesión particular y para recolectar
                                        información demográfica general. Se establece que en cualquier momento los Usuarios
                                        podrán solicitar la eliminación de su cuenta e información de los Sistemas de
                                        Información de QUEO, a través del procedimiento previsto en la Sección 11 de la
                                        Política. QUEO recoge y almacena automáticamente cierta información sobre la actividad
                                        de los Usuarios dentro de las Plataformas. Tal información puede incluir, sin limitarse,
                                        la URL de la que provienen (estén o no en nuestro Portal), a qué URL acceden
                                        seguidamente (estén o no en nuestro Portal), tipo de dispositivo usado, qué navegador
                                        están utilizando, así como también las páginas visitadas, las búsquedas realizadas, las
                                        publicaciones, compras o ventas, mensajes, identificador de dispositivo, entre otra
                                        información.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.3. Finalidades del Tratamiento de
                                        Información Protegida de Usuarios. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> En virtud de la calidad
                                        de intermediador que QUEO ostenta entre los Usuarios y los propietarios de parqueaderos
                                        privados y/o públicos, y principalmente con el fin de suministrar un excelente servicio
                                        para que los Usuarios puedan realizar operaciones en forma ágil y segura, QUEO solicita
                                        y almacena Información Protegida. El propósito final y último de la recolección de esta
                                        información se funda en la base de que nuestra relación de intermediación se pueda
                                        concretar exitosamente. Es decir, la información que recolectamos se recaba y guarda con
                                        el fin de poder suministrar esta información a los propietarios de parqueaderos privados
                                        y/o públicos afiliados a QUEO, de manera tal que la relación final de consumo, entre
                                        consumidor y oferente del servicio, se pueda concretar de manera exitosa. Lo anterior no
                                        obsta para que, así mismo, esta información pueda ser utilizada por la Compañía para que
                                        a través de las Plataformas podamos ofrecerle a los Usuarios servicios y funcionalidades
                                        que se adecuen mejor a sus necesidades, permitiéndonos hacerle llegar por distintos
                                        medios y vías (incluyendo correo electrónico, notificaciones PUSH y mensajes cortos de
                                        texto (SMS)) información relativa a ofertas de productos y servicios que consideramos
                                        pueden ser de interés de nuestros Usuarios. Teniendo en cuenta lo anterior, Información
                                        Protegida que recabamos tienen las siguientes finalidades: Suministrar a los
                                        propietarios de parqueaderos privados y/o públicos y demás oferentes de los servicios,
                                        la información necesaria para coordinar y formalizar la relación de consumo de dichos
                                        servicios. Ponernos en contacto, cada vez que la Compañía lo considere oportuno y
                                        convenientes, con el fin de ofrecerle por distintos medios y vías (incluyendo correo
                                        electrónico, notificaciones PUSH y mensajes cortos de texto (SMS)), ofertas otros
                                        productos y/o servicios publicados en las Plataformas. Desarrollar estudios internos
                                        sobre los intereses, el comportamiento y la demografía de los Usuarios con el objetivo
                                        de comprender mejor sus necesidades e intereses y darle un mejor servicio o proveerles
                                        información relacionada. Mejorar nuestras iniciativas comerciales y promocionales para
                                        analizar las páginas más visitadas por los Usuarios, las búsquedas realizadas,
                                        perfeccionar nuestra oferta de contenidos, personalizar dichos contenidos, presentación
                                        y servicios. Realizar una calificación del servicio prestado y autorizar la
                                        actualización de la información publicada mediante una moderación de contenido, que
                                        pueden ser eliminados o modificados por QUEO. Enviar información o mensajes sobre nuevos
                                        productos y/o servicios, mostrar publicidad o promociones, banners, de interés para
                                        nuestros Usuarios, noticias sobre QUEO, además de toda otra información que creamos
                                        conveniente. El Usuario podrá en cualquier momento solicitar que lo excluyan de las
                                        listas para el envío de información promocional o publicitaria. Compartir la Información
                                        Protegida con empresas de servicios o empresas de "outsourcing", que recibirán la
                                        información en calidad de Encargados del Tratamiento, y contribuirán a mejorar o a
                                        facilitar las operaciones de QUEO, incluyendo dichos terceros, entre otros, pero sin
                                        limitarse a: medios de pago, seguros o intermediarios en la gestión de pagos. Estas
                                        compañías generalmente tienen políticas sobre confidencialidad de la información
                                        similares a las de QUEO. Sin embargo, QUEO velará porque se cumplan los estándares
                                        mínimos, mediante la firma de acuerdos o convenios cuyo objeto sea la privacidad de su
                                        Información Protegida. No obstante, QUEO no se hace responsable por el uso indebido de
                                        la Información Protegida de los Usuarios que realicen estas empresas, cuando
                                        directamente y dentro de su actividad, dichas empresas ostenten la calidad de
                                        Responsable del Tratamiento. Tal situación se podrá presentar, cuando en algunos casos,
                                        estos proveedores de servicios sean quienes recojan información directamente del Usuario
                                        (por ejemplo, si les solicitamos que realicen encuestas o estudios). En tales casos,
                                        recibirá una notificación acerca de la participación de un proveedor de servicios en
                                        tales actividades, y quedará a su entera discreción toda la información que quiera
                                        brindarle y los usos adicionales que autorice a los proveedores realizar sobre su
                                        Información Protegida. En caso de que facilite, por propia iniciativa información
                                        adicional a dichos prestadores de servicios de manera directa, tales prestadores usarán
                                        esa información conforme a sus propias políticas de tratamiento de datos personales, las
                                        cuales les recomendamos soliciten directamente al prestador del servicio para conocer
                                        sus derechos y finalidades del tratamiento de la información que entreguen. Suministrar
                                        la Información Protegida a las entidades que intervengan en la resolución de disputas
                                        entre los mismos, tales como compañías de seguros o tribunales competentes para
                                        solucionar tales disputas. Transferencia internacional de Sistemas de Información a las
                                        Sociedades Vinculadas con propósitos de mercadeo. Mejorar nuestros mecanismos de
                                        seguridad de la información, con el fin de prevenir el fraude e implementación de
                                        herramientas o actividades no autorizadas.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.4Disposiciones relativas a la edad para
                                        acceder a los productos y/o servicios de QUEO.  </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Los productos y/o
                                        servicios prestados a través de las Plataformas sólo están disponibles para aquellas
                                        personas que tengan capacidad legal para contratar. Por lo tanto, aquellos que no
                                        cumplan con esta condición, deberán abstenerse de suministrar información personal para
                                        ser incluida en nuestros Sistemas de Información.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.5 Uso de Cookies. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Como Usuario de las
                                        Plataformas conoce y acepta que QUEO podrá utilizar un sistema de seguimiento mediante
                                        la utilización de cookies. Las cookies son pequeños archivos que se instalan en el disco
                                        rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios.
                                        También ofrecemos ciertas funcionalidades que sólo están disponibles mediante el empleo
                                        de cookies. Estas se utilizan con el fin de conocer los intereses, el comportamiento y
                                        la demografía de quienes visitan o son visitantes de nuestras Plataformas (especialmente
                                        el Portal) y de esa forma, comprender mejor sus necesidades e intereses y darles un
                                        mejor servicio o proveerle información relacionada. También usaremos la información
                                        obtenida por intermedio de las cookies para analizar las páginas navegadas por el
                                        Usuario y las búsquedas realizadas, con el fin de mejorar nuestras iniciativas
                                        comerciales y promocionales, mostrar publicidad o promociones, banners de interés,
                                        enviar noticias sobre QUEO, perfeccionar nuestra oferta de contenidos y artículos, y
                                        personalizar dichos contenidos, presentación y servicios. Adicionalmente, QUEO utiliza
                                        las cookies para que el Usuario no tenga que introducir su clave tan frecuentemente
                                        durante una sesión de navegación, contabilizar y corroborar las registraciones, la
                                        actividad del Usuario y otros conceptos y acuerdos comerciales, siempre teniendo como
                                        objetivo el beneficio del Usuario. QUEO no las usará con otros fines. Se establece que
                                        la instalación, permanencia y existencia de las cookies en el computador o dispositivo
                                        de acceso del Usuario depende de su exclusiva voluntad y puede ser eliminada del mismo
                                        cuando así lo desee. Para saber cómo eliminar las cookies del sistema es necesario
                                        revisar la sección “Ayuda” (Help) del navegador. También se pueden encontrar cookies u
                                        otros sistemas similares instalados por terceros en ciertas páginas del Portal. QUEO no
                                        controla el uso de cookies por parte de terceros.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.6 E-mails, SMS y notificaciones
                                        PUSH. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Podemos enviarle
                                        e-mails, mensajes cortos de texto SMS, o notificaciones PUSH como parte de un proceso
                                        inherente al desarrollo y prestación de nuestros servicios, o en las siguientes
                                        circunstancias: Luego del proceso de registro, notificándole los datos de su cuenta. Los
                                        e-mails o notificaciones PUSH establecidos en nuestros Términos y Condiciones, en lo
                                        referente al procedimiento de pedido –léase mail de recepción de la orden de pedido, de
                                        confirmación de pedido, etc.– E-mails o notificaciones PUSH con recordatorios de los
                                        servicios que ofrecemos (especialmente aquellos que aún no haya utilizado o no haya
                                        utilizado en un tiempo considerable). E-mails o notificaciones PUSH con noticias y
                                        novedades, como parte de un Newsletter. ➢ E-mails o notificaciones PUSH promocionales.
                                        E-mails o notificaciones PUSH para ofrecer servicios relacionados. En todo caso, en cada
                                        uno de los e-mails que enviemos, siempre ofreceremos la posibilidad de cancelar la
                                        suscripción (opt-out) para dejar de recibirlos en el futuro.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 1.7. Seguridad y almacenamiento.
                                    </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Empleamos diversas
                                        técnicas de seguridad para proteger la Información Protegida de accesos no autorizados
                                        por visitantes de las Plataformas, especialmente al Portal, de dentro o fuera de la
                                        Compañía. QUEO dentro de sus obligaciones legales reportará a las autoridades
                                        competentes aquellos incidentes, interceptaciones ilegales o violación de sus Sistemas
                                        de Información por parte de personas no autorizadas, adoptando las medidas correctivas y
                                        preventivas del caso, a su alcance para mitigar o solucionar estas situaciones. En todo
                                        caso, QUEO no se hace responsable de vulneraciones o afectaciones a sus sistemas, por
                                        eventos que se encuentran por fuera de su control razonable y los estándares exigidos
                                        para la prestación de sus servicios, por lo cual no seremos igualmente responsables por
                                        la indebida utilización de la información obtenida por esos medios fraudulentos y por la
                                        falta de diligencia de los Usuarios en el cumplimiento de sus obligaciones y deberes al
                                        registrarse dentro de las Plataformas. Para los procedimientos de borrado, existe la
                                        eliminación total de los datos para aquellas bases de datos manuales y electrónicas que
                                        no manejan integridad referencial. Para bases de datos con integridad referencial la
                                        eliminación parcial de los datos incluye la ejecución de scripts validados para ir
                                        secuencialmente por las tablas y campos de las bases de datos que almacenen datos
                                        personales y se realizará la actualización de la información personal por un conjunto de
                                        símbolos asterisco (*) o similar anonimizándolo de manera no reversible y eliminando la
                                        información personal identificable del titular. Las copias de seguridad pueden contener
                                        Información Protegida hasta por un periodo de dos (2) años, las cuales se encuentran
                                        inaccesibles a los ambientes y herramientas utilizadas en producción y solo se utilizan
                                        para realizar procedimientos de recuperación de desastres. Capítulo 2. Disposiciones
                                        aplicables para candidatos o potenciales colaboradores de QUEO y las Sociedades
                                        Vinculadas (los "Candidatos"), colaboradores o empleados actuales o retirados de QUEO
                                        bajo relación laboral o similar (en adelante los "Colaboradores"), grupo familiar y/o
                                        beneficiarios de los Colaboradores (en adelante los "Beneficiarios Vinculados").</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.1. Protegiendo la Información Protegida
                                        de los Candidatos, Colaboradores y Beneficiarios Vinculados. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO está comprometido
                                        en proteger la privacidad e Información Protegida de los Candidatos, Colaboradores y
                                        Beneficiarios Vinculados a QUEO. En este sentido, en este Capítulo 2, se describe además
                                        de la Información Protegida que QUEO recolecta de los anteriores Titulares y los fines
                                        del Tratamiento de dicha información, los medios y canales a través de los cuales
                                        obtenemos su consentimiento para dicho tratamiento, anotando que QUEO nunca recolectará
                                        Información Protegida sin su consentimiento. A través de la Política y de este Anexo A,
                                        aseguramos que cualquier Información Protegida que sea entrega o recolectada de los
                                        Titulares indicados en el título de este Capítulo 2, será mantenida en forma segura y
                                        reservada.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.2. Mecanismos de autorización y
                                        otorgamiento consentimiento. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Con la formalización de
                                        una relación laboral, la Compañía recolectará información y datos de Colaboradores, y en
                                        algunos casos, dicha información tendrá el carácter de Información Protegida. Dado que
                                        Beneficiarios Vinculados podrán depender económicamente de nuestros Colaboradores, y en
                                        virtud de la legislación laboral, se podrá requerir la obtención de Información
                                        Protegida de los Beneficiarios Vinculados para proceder con afiliaciones en los sistemas
                                        de seguridad social, entidades prestadoras de servicios de salud o demás entidades
                                        competentes, o para desarrollar o cumplir con disposiciones legales aplicables, razón
                                        por la cual el Colaborador reconoce y acepta con la suscripción del Contrato de Trabajo,
                                        contrato de aprendizaje, convenio de práctica, entre otro documento, así como del
                                        formato de Autorización de Tratamiento de Datos Personales, que QUEO recolecte o
                                        solicite Información Protegida de sus Beneficiarios Vinculados. El Colaborador con la
                                        suscripción del contrato correspondiente, y el formato de Autorización de Tratamiento de
                                        Datos Personales está de manera inequívoca entregando su consentimiento previo, expreso
                                        e informado para el Tratamiento de su Información Protegida, y la de sus Beneficiarios
                                        Vinculados, por parte de QUEO para las finalidades descritas en la autorización
                                        correspondiente, y las contempladas en el numeral 2.4 siguiente. Los Candidatos
                                        reconocen y aceptan que con la participación dentro de los procesos de selección de
                                        personal de QUEO, la suscripción del formato de Autorización de Tratamiento de Datos
                                        Personales y/o el envío de la información y documentación necesaria para la
                                        participación dentro de un proceso de selección, constituye de manera inequívoca el
                                        consentimiento previo, expreso e informado para el Tratamiento de su Información
                                        Protegida por parte de QUEO para las finalidades descritas en la autorización
                                        correspondiente y las contempladas en el numeral 2.4 siguiente.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.3. Información (incluida Información
                                        Protegida) que recolectamos y recabamos. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Frente a Candidatos:
                                        QUEO podrá recolectar, entre otra, la siguiente información: Nombres y apellidos. Tipo y
                                        número de identificación. Nacionalidad. Fecha de Nacimiento / Lugar de Nacimiento
                                        Género. Estado civil. Teléfonos fijos y celulares de contacto. Direcciones postales y
                                        electrónicas (personales y/o laborales). Profesión u oficio. Perfil Académico. Perfil
                                        Profesional. Envío hoja de vida actualizada. Frente a Colaboradores: QUEO podrá
                                        recolectar, entre otra, la siguiente información: Nombres y apellidos. Tipo y número de
                                        identificación. Fecha de nacimiento. Género. Estado civil. Teléfono residencia. Teléfono
                                        celular. Direcciones postales y electrónicas (personales y/o laborales). Profesión u
                                        oficio. Perfil Académico. Perfil Profesional. Hoja de vida actualizada. Fecha Ingreso.
                                        Fecha de Nacimiento / Lugar de Nacimiento Nacionalidad. Grupo sanguíneo. Nombre de los
                                        padres. Empleo(s) anterior(es). Deficiente Físico. Grado de parentesco o afinidad con
                                        algún Colaborador. Tipo Contrato. Término Contrato. Cargo. Salario. Funciones. Jornada
                                        mensual. Jornada semanal. Tipo pago. Banco pago. Fondo Cesantía. Fondo Salud. Fondo
                                        Pensión. Caja Compensación. Entidad prestadora de salud – Medicina Prepagada. Historias
                                        clínicas. Exámenes físicos de ingreso / salida. Datos biométricos (fotografía,
                                        grabaciones de video, grabaciones de voz, huella dactilar) Frente a Beneficiarios
                                        Vinculados: QUEO podrá recolectar, entre otra, la siguiente información: Nombre
                                        (Beneficiario Vinculado). Nombre Colaborador del que es Beneficiario Vinculado Fecha
                                        inicio vigencia (fecha ingreso a la Compañía). Fecha nacimiento (de Beneficiario
                                        Vinculado). Nacionalidad. Departamento. País. Sexo. Parentesco o afinidad con
                                        Colaborador.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.4. Finalidades del Tratamiento de
                                        Información Protegida de Candidatos, Colaboradores y Beneficiarios Vinculados.
                                    </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> Dentro del giro
                                        ordinario de nuestra actividad, y con el fin de desarrollar y ejecutar las relaciones de
                                        índole laboral necesarios para cumplir con nuestro objeto social y con la normatividad
                                        vigente, QUEO realizará el Tratamiento de Información Protegida con base en la
                                        autorización recibida de los Candidatos, Colaboradores y Beneficiarios Vinculados, para,
                                        entre otras, las siguientes finalidades: Finalidades del Tratamiento de Información
                                        Protegida de Candidatos: Contacto con el Candidato dentro del proceso de selección
                                        específico. Verificar y obtener referencias de personas naturales o jurídicas, indicadas
                                        en hojas de vida, formularios o documentos acompañados o entregados a QUEO y/o las
                                        Sociedades Vinculadas durante el proceso de selección y/o que hayan sido ingresados y/o
                                        incorporados de manera directa por el Candidato en los Sistemas de Información.
                                        Consolidar dicha información en los Sistemas de Información o en una base de datos
                                        administrada por terceros debidamente autorizados por QUEO o las Sociedades Vinculadas,
                                        que le permitan y faciliten tanto a QUEO como a las Sociedades Vinculadas: (a) la
                                        administración y control de sus procesos de selección; (b) remitir al Candidato
                                        información de oportunidades de empleo y de negocio; (c) el estudio, análisis y
                                        evaluación de los Candidatos que participen de procesos de selección abiertos y
                                        vigentes; (d) para control interno y de auditoría requerido por QUEO o las Sociedades
                                        Vinculadas; y (e) para atender oportunamente, solamente entregando la información
                                        específicamente solicitada, los requerimientos de orden legal o judicial que realicen
                                        órganos de control, autoridades competentes y judiciales, en Colombia o en los países
                                        donde se encuentran ubicadas las Sociedades Vinculadas. Finalidades del Tratamiento de
                                        Información Protegida de Colaboradores: Realizar al interior de la Compañía actividades
                                        de bienestar para los Colaboradores. Conservar la información exacta de residencia de
                                        los Colaboradores cuando se requiera hacer visitas domiciliarias como procedimiento de
                                        ingreso o para el desarrollo de actividades de acompañamiento dentro de los programas de
                                        bienestar o se requiera envió de correspondencia. Hacer actividades de vigilancia
                                        epidemiológica enmarcadas en el programa de salud ocupacional. Contactar a un familiar
                                        en caso de una emergencia presentada al interior de la Compañía. Tener un registro de
                                        fechas de ingreso y edad de los Colaboradores cotizantes a la AFP (Administradora de
                                        Fondo de Pensiones) y para prestar un apoyo en el proceso de solicitud de pensión.
                                        Ofrecer un apoyo a los Colaboradores en trámites ante la EPS, por inconsistencias en
                                        atención personal y de Beneficiarios Vinculados. Brindar un apoyo a los Colaboradores en
                                        trámites ante la Caja de Compensación Familiar, por inconsistencias en atención o
                                        afiliación personal y de Beneficiarios Vinculados. Toma de decisiones en materia laboral
                                        con respecto a la ejecución y terminación del contrato de trabajo bien sea por el área
                                        jurídica de la Compañía o su asesor externo. Realizar los pagos, afiliaciones y registro
                                        de novedades a las entidades prestadoras de servicios de seguridad social, aportes de
                                        ley, pagos parafiscales, cuentas AFC, a las entidades prestadoras de salud, de pensiones
                                        y cesantías, incluyendo para el Colaborador y de ser requerido, de sus Beneficiarios
                                        Vinculados (cónyuge, hijos y personas a cargo). Suministrar la información personal y
                                        financiera a terceros para el pago de préstamos, viáticos, vuelos, libranzas o
                                        diferentes cuentas que informe. Previa notificación a las centrales de riesgo, realizar
                                        el reporte a entidades de control nacional o territorial (DIAN, UIAF, entre otras
                                        entidades). Contacto para futuras contrataciones u ofertas laborales dentro y fuera del
                                        país. Las demás necesarias y que se presente en el entorno de la ejecución laboral.
                                        Finalidades del Tratamiento de Información Protegida de Beneficiarios Vinculados:
                                        Conservar la información exacta de residencia de los Colaboradores y Beneficiarios
                                        Vinculados cuando se requiera hacer visitas domiciliarias como procedimiento de ingreso
                                        o para el desarrollo de actividades de acompañamiento dentro de los programas de
                                        bienestar o se requiera envió de correspondencia. Contactarlo en caso de una emergencia
                                        presentada al interior de la Compañía referente al Colaborador. Adelantar cuando sea
                                        necesario los trámites ante Cajas de Compensación Familiar, entidades prestadoras de
                                        servicios de seguridad social y prestadoras de salud, y demás entidades las afiliaciones
                                        en calidad de beneficiarios del Colaborador que sean necesarias. Las demás relacionadas
                                        directamente con el Tratamiento de Información Protegida de los Colaboradores que
                                        requiera en virtud de un mandato legal o contractual, el Tratamiento de Información
                                        Protegida de los Beneficiarios Vinculados.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 2.5. Disposiciones relativas a la edad
                                        para ser Colaborador de QUEO. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> TeQUEO dentro de sus
                                        procesos de contratación de Colaboradores da cumplimiento a la normatividad laboral
                                        aplicable referente a la edad mínima para contratar bajo una relación laboral y
                                        cualquier otra figura contemplada en la ley, a Menores de Edad.st</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> Capítulo 3. Disposiciones aplicables para
                                        proveedores de QUEO (los "Proveedores"). </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> </p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 3.1. Protegiendo la Información Protegida
                                        de los Proveedores. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO está comprometido
                                        en proteger la privacidad e Información Protegida de los Proveedores. A través de la
                                        Política y de este Anexo A, aseguramos que cualquier Información Protegida que sea
                                        entrega o recolectada de los Proveedores será mantenida en forma segura y reservada.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 3.2.Mecanismos de autorización y
                                        otorgamiento consentimiento. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> La Compañía recolectará
                                        información y datos de Proveedores, y en algunos casos, dicha información tendrá el
                                        carácter de Información Protegida. El Proveedor con la suscripción del contrato
                                        correspondiente, y el formato de Autorización de Tratamiento de Datos Personales está de
                                        manera inequívoca entregando su consentimiento previo, expreso e informado para el
                                        Tratamiento de su Información Protegida por parte de QUEO para las finalidades descritas
                                        en la autorización correspondiente, y las contempladas en el numeral 3.4 siguiente. Los
                                        Proveedores reconocen y aceptan que con la suscripción del formato de Autorización de
                                        Tratamiento de Datos Personales y/o el envío de la información y documentación necesaria
                                        para la ejecución de un contrato con QUEO, constituye de manera inequívoca el
                                        consentimiento previo, expreso e informado para el Tratamiento de su Información
                                        Protegida por parte de QUEO para las finalidades descritas en la autorización
                                        correspondiente y las contempladas en el numeral 3.4 siguiente.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 3.3.Información (incluida Información
                                        Protegida) que recolectamos y recabamos. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> QUEO podrá recolectar,
                                        entre otra, la siguiente información: Razón Social /Nombre Comercial / Enseña comercial
                                        y otros nombres que identifican al Proveedor. Información de identificación tributaria
                                        (NIT, entre otros). Información bancaria para pagos. Personas de contacto en la
                                        ejecución del contrato correspondiente. Copia de documentos soporte de la información
                                        financiera, tributaria y bancaria del Proveedor. Dirección postal, correo electrónico,
                                        número de teléfono y/o demás datos de identificación del Proveedor.</p>
                                </div>
                                <div className="jsx-1632496189">
                                    <p className="jsx-1632496189 queo-font-terms"> 3.4. Finalidades del Tratamiento de
                                        Información Protegida de Proveedores. </p>
                                    <p className="jsx-1632496189 queo-font-section queo-font-terms"> La información
                                        recopilada podrá ser utilizada para las siguientes finalidades: Llevar a cabo
                                        evaluaciones y selección de proveedores potenciales. Establecer relaciones de negocio
                                        para adquirir bienes o servicios a favor de la Compañía y/o las Sociedades Vinculadas.
                                        Control, administración y manejos de pagos por los bienes y servicios recibidos.
                                        Cumplimiento de disposiciones fiscales y legales con entidades y autoridades competentes
                                        (incluyendo entidades gubernamentales y autoridades regulatorias). Evaluaciones
                                        cualitativas y cuantitativas de niveles de servicio de los proveedores. Consultas,
                                        auditorías y revisiones derivadas de las relaciones de negocio y servicios con el
                                        Proveedor. Cualquier otra actividad necesaria para el efectivo cumplimiento de la
                                        relación comercial entre el Proveedor y QUEO. Cuando se trate de Datos Personales
                                        Crediticios, su información financiera y crediticia sea incluida en las bases de datos
                                        que para el efecto se constituyan y que cumplan con los requisitos establecidos por la
                                        ley para su creación y administración, y en todo momento verifique la Compañía el
                                        comportamiento crediticio en las centrales de riesgo. Control y prevención del fraude.
                                        Control y prevención del lavado de activos y financiación del terrorismo. Verificación y
                                        monitoreo de las transacciones realizadas. Auditoría y verificación del historial de
                                        incidentes relacionados con el lavado de activos, financiación del terrorismo y/o
                                        fraudes. Cumplimiento de las obligaciones relacionadas con la prevención y control del
                                        lavado de activos, financiación del terrorismo, y/o fraudes. Dar cumplimiento a
                                        requerimientos realizados por autoridades competentes. Consulta y reporte de información
                                        ante operadores de bancos de datos de contenido crediticio y financiero. Fines
                                        comerciales y publicitarios relacionados con productos y/o servicios ofrecidos y
                                        prestados por parte de la Compañía y/o las Sociedades Vinculadas. Consolidar dicha
                                        información en un Sistema de Información administrado por la Compañía o las Sociedades
                                        Vinculadas. </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }

                .privacy-section {
                    padding-top: 130px;
                }
            `}</style>
        </>
    );
};

export default LandingPrivacy;




