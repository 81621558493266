import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import {
    HeroProduct,
    Intro,
    ProductFeatures,
    ProductExtraFeatures,
    ProductVideo,
    ProductFeatureHighlight,
} from '../sections/LandingCommonSections';

const LandingAccess = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroProduct video="access" title="Control de acceso sin contacto" product="queo access" customClass="access" />
                <Intro 
                    intro="Queo Access tiene para tu edificio u oficina una plataforma de acceso y control sin contacto físico resguardada en la nube que brinda la mayor seguridad y reportes de ingreso en tiempo real. Permitiéndote tener el control de todas las personas que lleguen a tu copropiedad."
                />
                <ProductFeatures title="Queo Access, control de acceso" features={features} image="access" product="queo access"/>
                <ProductFeatureHighlight 
                    title="Ten un formulario de salubridad para todos" 
                    images={{
                        mobile: 'queo-access-health-form-mobile.webp',
                        tablet: 'queo-access-health-form-tablet.webp',
                        desktop: 'queo-access-health-form.webp'
                    }}
                    imagePosition="right"
                    page="access" 
                    product="queo access"
                />
                <ProductExtraFeatures title="Queo Access también te permite tener..." features={extraFeatures} />
                <ProductVideo video="access" title="Conoce cómo funciona" subtitle="Queremos ofrecerte facilidad a través de la tecnología. Es momento de hacer de tu oficina, un espacio inteligente." />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

const features = [
    {
        title: 'Creación de usuarios y roles',
        description: 'Con Queo Access podrás crear o invitar a los usuarios de una forma muy fácil. Además, determina qué rol tendrá cada persona, desde un usuario básico hasta administradores del edificio u oficinas.',
        icon: 'fi-rr-user-add',
    },

    {
        title: 'Análisis e informes',
        description: 'Podrás ver, en tiempo real, la data del comportamiento de los usuarios en tus instalaciones y descargarlo para la toma de decisiones. Obtendrás la información de cuántas personas han ingresado, en qué lugar hay más cantidad de usuarios, o cuál es la hora con más afluencia.',
        icon: 'fi-rr-stats',
    },

    {
        title: 'Ingreso sin contacto físico',
        description: 'Cada usuario, sea empleado o visitante, podrá ingresar a la copropiedad sin contacto físico con la aplicación de Queo Access desde su teléfono, esto con el fin de minimizar la propagación de virus y enfermedades, de esta forma no será necesario tocar superficies compartidas.',
        icon: 'fi-rr-smartphone',
    },
];

const extraFeatures = [
    {
        title: 'Trazabilidad en tiempo real',
        description: 'Podrás tener una trazabilidad, hacer seguimiento y ver en tiempo real la actividad que se realiza en tu edificio u oficina, también quién se encuentra en la copropiedad y cuánto tiempo ha estado.',
        icon: 'fi-rr-earnings',
    },

    {
        title: 'Credenciales Digitales',
        description: 'Cada usuario tendrá a disposición un carné digital con sus credenciales. Estas credenciales son intransferibles y no pueden ser clonadas, manteniendo tu espacio 100% seguro.',
        icon: 'fi-rr-mode-portrait',
    },

    {
        title: 'Gestión de visitantes',
        description: 'Recibe a tus visitantes de una forma segura y sin contacto, cada visitante podrá ingresar con un pre-registro para evitar aglomeraciones y reducir los tiempos.',
        icon: 'fi-rr-user-time',
    },
];

export default LandingAccess;