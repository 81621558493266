import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const bucketURL = process.env.AWS_S3_BUCKET_URL ? process.env.AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const OneStepFeature = (props) => {
    const {
        title,
        feature,
        icon,
    } = props;

    return (
        <div className="one-step-feature">
            <div className="feature-header-wrapper">
                {icon !== 'icon-app' && <div className="icon-circle-wrapper">
                    <i className={'qa-font-' + icon}></i>
                </div>}
                {icon === 'icon-app' && <LazyLoadImage
                    className="story-card-logo"
                    alt="logo app Queo Access"
                    src={`${bucketURL}/media/web/${icon}.webp`}
                    width="100px" height="100px" />}
                <h3 className="feature-title"><strong>{title}</strong> {feature}</h3>
            </div>
        </div>
    );
};

export default OneStepFeature;