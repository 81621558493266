import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const bucketURL = process.env.AWS_S3_BUCKET_URL ? process.env.AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const Animation = () => {
    const imagesPath = `${bucketURL}/media/landings/images/animation/svg/`;
    return (
        <>
            <div className="animation">
                <LazyLoadImage
                    className="stage"
                    alt="Queo Parking"
                    src={imagesPath + 'stage.svg'} />
                <a href="/optimizacion-de-parqueaderos" id="buttonCar">
                    <label>
                        Ingreso a parqueadero
                    </label>
                </a>
                <LazyLoadImage
                    className="car"
                    alt="Queo Parking carro entrando"
                    src={imagesPath + 'car.svg'} />
                <LazyLoadImage
                    id="talanqueraCerrada"
                    alt="Queo Access talanquera abierta"
                    src={imagesPath + 'talanquera1.svg'} />
                <LazyLoadImage
                    id="talanqueraAbierta"
                    alt="Queo Access talanquera cerrada"
                    src={imagesPath + 'talanquera2.svg'} />
                <a href="/control-de-acceso" id="buttonMan1">
                    <label>
                        Acceso concedido
                    </label>
                </a>
                <LazyLoadImage
                    id="man1"
                    className="man"
                    alt="Queo Access lockers"
                    src={imagesPath + 'man1.svg'} />
                <a href="/lockers-inteligentes" id="buttonMan2">
                    <label>
                        Locker desbloqueado
                    </label>
                </a>
                <LazyLoadImage
                    id="man2"
                    className="man"
                    alt="Queo Access lockers"
                    src={imagesPath + 'man2.svg'} />
                <a href="/puestos-colaborativos" id="buttonMan3">
                    <label>
                        Puesto confirmado
                    </label>
                </a>
                <LazyLoadImage
                    id="man3"
                    className="man"
                    alt="Queo Access puestos"
                    src={imagesPath + 'man3.svg'} />
                <a href="/salas" id="buttonMan4">
                    <label>
                        Sala reservada
                    </label>
                </a>
                <LazyLoadImage
                    id="man4"
                    className="man"
                    alt="Queo Access salas"
                    src={imagesPath + 'man4.svg'} />
            </div>
        </>
    );
};

export default Animation;
