import React from 'react';
import {
    NavbarQueo,
    OurServices,
    SuccessStories,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import HeroFreeTrial from '../sections/HeroFreeTrial';

const LandingFreeTrial = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroFreeTrial />
                <SuccessStories
                    title="Estas son las empresas que están volviendo a la presencialidad con Queo Access"
                    reverse={true}
                />
                <OurServices link="#contacto" />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

export default LandingFreeTrial;
