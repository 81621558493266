import React from 'react';
import {
    NavbarQueo,
    Hero,
    WhoMentionUs,
    OurServices,
    SuccessStories,
    OneStep,
    Download,
    Support,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';


const LandingHome = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <Hero />
                <WhoMentionUs />
                <OurServices />
                <SuccessStories />
                <OneStep />
                <Support />
                <Download />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

export default LandingHome;
