import React from 'react';
import { Row, Col } from 'react-bootstrap';

const LandingSectionTitle = (props) => {
    const {
        text,
        theme,
        alignment,
        line,
        lineWidth,
        otherClasses,
        ...otherProps
    } = props;

    return (
        <>
            <Row className={'justify-content-' + alignment}>
                <Col>
                    <div className={['wrapper-landing-title', alignment].join(' ')}>
                        <h2 className={[
                            'landing-section-title', 
                            theme || 'light', 
                            alignment || 'center', 
                            lineWidth || 'lw-80',
                            (line === false) ? 'no-line': ''
                        ].concat(otherClasses).join(' ')} {...otherProps}>{ text }</h2>
                    </div>
                </Col>
            </Row>
            <style jsx>{`
                .wrapper-landing-title {
                    margin: auto;
                }

                .wrapper-landing-title.center {
                    text-align: center;
                }

                .wrapper-landing-title.right {
                    text-align: right;
                }

                .wrapper-landing-title.left {
                    text-align: left;
                }

                .landing-section-title {
                    position: relative;
                    display: inline-block;
                    padding-bottom: 25px;
                    margin: auto;
                    font-size: 2.2rem;
                    font-weight: 700;
                    text-align: center;
                    line-height: 50px;
                }

                .landing-section-title.no-line:before {
                    display: none;
                }

                .landing-section-title.light {
                    color: #222;
                }

                .landing-section-title.dark {
                    color: #FFF;
                }

                .landing-section-title.light:before {
                    background-color: #FE8402;
                }

                .landing-section-title.dark:before {
                    background-color: rgba(255, 255, 255, 0.5);
                }

                .landing-section-title:before {
                    margin: auto;
                    height: 5px;
                }

                .landing-section-title.center:before {
                    left: 0;
                    right: 0;
                }

                .landing-section-title.left:before {
                    left: 0;
                }

                .landing-section-title.right:before {
                    right: 0;
                }

                .landing-section-title:before {
                    content: '';
                    position: absolute;
                    bottom: 10px;
                }

                .landing-section-title.lw-100:before {
                    width: 100%;
                }
                
                .landing-section-title.lw-80:before {
                    width: 80%;
                }

                .landing-section-title.lw-50:before {
                    width: 50%;
                }

                .landing-section-title.lw-40:before {
                    width: 40%;
                }

                .landing-section-title.lw-30:before {
                    width: 30%;
                }

            `}</style>
        </>
    );
};

export default LandingSectionTitle;