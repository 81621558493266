import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { MobileTablet, Desktop } from '../components/common/responsive-devices';
import LandingSectionTitle from '../components/common/section-title';
import ServicesFeature from '../components/services/feature';

const features = [
    {
        title: 'Controla con mayor eficiencia los espacios colaborativos de tu empresa',
        image: 'puestos-colaborativos',
        description: 'Con Queo Spaces, tus colaboradores pueden reservar desde cualquier lugar espacios de trabajo y aprovecharlos cuando vayan a la oficina.',
        link: '/puestos-colaborativos',
        linkText: 'Conoce Espacios de trabajo'
    },

    {
        title: 'Gestiona tus salas de reunión',
        image: 'salas',
        description: 'Tus colaboradores pueden reservar salas de reunión con invitados. Los administradores hacen seguimiento del uso de las salas para tomar decisiones y controlar el inventario.',
        link: '/salas',
        linkText: 'Conoce Salas de reunión'
    },

    {
        title: 'Gestiona los Lockers de forma digital',
        image: 'lockers',
        description: 'La gestión de estos lockers se realiza de manera digital, lo que permite llevar un control preciso de su uso y su disponibilidad en tiempo real.',
        link: '/lockers-inteligentes',
        linkText: 'Conoce Lockers Inteligentes'
    },

    {
        title: 'Optimiza tiempos y espacios dentro de tu empresa',
        image: 'control-de-acceso',
        description: 'Con Queo Access, controla el acceso y el aforo de tus colaboradores en las instalaciones. Además, reduce costos y automatiza el proceso de ingreso de los visitantes.',
        link: '/control-de-acceso',
        linkText: 'Conoce Queo Access'
    },

    {
        title: 'Aprovecha al 100% tus parqueaderos',
        image: 'optimizacion-de-parqueaderos',
        description: 'Con Queo Parking, optimiza los parqueaderos de tu oficina para que tus colaboradores los reserven cuando los necesiten.',
        link: '/optimizacion-de-parqueaderos',
        linkText: 'Conoce Queo Parking'
    },
];

const OurServices = () => {
    return (
        <>
            <section id="soluciones" className="wrapper-section our-solutions">
                <Container fluid="xl">
                    <Row className="justify-content-center mt-2">
                        <Col xs={12}>
                            <LandingSectionTitle
                                text="Conoce nuestros servicios"
                                alignment="center"
                                theme="light"
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-2">
                        { features.map((feature, i) => <Col xs={12} lg={6} key={i}>
                            <ServicesFeature {...feature} />
                        </Col>)}

                        <Col xs={12} lg={6}>
                            <ExtraStory />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

const ExtraStory = (props) => {
    const {
        link
    } = props;

    return (
        <>
            <div className="services-feature-extra">
                <div className="extra-feature-content">
                    <i className="qa-font-check"></i>
                    <MobileTablet>
                        <h2 className="text-3xl medium extra-feature-title">¡El control de tu infraestructura en un solo lugar!</h2>    
                    </MobileTablet>
                    <Desktop>
                        <h2 className="text-4xl medium extra-feature-title">¡El control de tu infraestructura en un solo lugar!</h2>
                    </Desktop>
                    <Button href={(link) ? link : '/asesoria-gratis#contacto'} block 
                        aria-label="solicitar prueba gratis"
                        className="button bg-transparent rounded-pill mx-auto queo-primary-btn light-theme extra-feature-btn">
                        ¡Quiero que me contacten!
                    </Button>
                </div>
            </div>
        </>
    );
};

export default OurServices;