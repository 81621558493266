import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MobileTablet, Desktop } from '../components/common/responsive-devices';
import LandingSectionTitle from '../components/common/section-title';


const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';
const successStoriesData = [
    {
        pic: 'nueva-eps',
        companyName: 'Nueva EPS',
        title: 'Coworking privado, lo nuevo de Nueva EPS para la \'nueva normalidad\'',
        logo: 'nueva-eps-logo',
        link: 'https://bit.ly/3ge8odG',
    },

    {
        pic: 'ecopetrol',
        companyName: 'Ecopetrol',
        title: 'Parqueaderos colaborativos, la apuesta de Ecopetrol para la reactivación',
        logo: 'ecopetrol-logo',
        link: 'https://bit.ly/2TlmeSC',
    },

    // {
    //     pic: 'terranum',
    //     companyName: 'Terranum',
    //     title: 'No más correos, así fue como Terranum agilizó sus actividades de ingreso',
    //     logo: 'terranum-logo',
    //     link: 'https://bit.ly/3vj3NLv',
    // },

    {
        pic: 'zolfunza',
        companyName: 'ZOL Funza',
        title: 'ZOL Funza, el proyecto logístico que optimiza procesos para grandes retailers del país',
        logo: 'zolfunza-logo',
        link: 'https://bit.ly/3wgQe0h',
    },
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1366 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1365, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1023, min: 768 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1
    }
};

const customers = [
    {
        picture: 'zolfunza',
        type: 'Parque logístico',
        link: 'https://bit.ly/3wgQe0h',
    },
    {
        picture: 'pei',
        type: 'Edificio',
        link: 'https://bit.ly/3izaXbR',
    },
    {
        picture: 'colpatria',
        type: 'Edificio',
        link: 'https://bit.ly/3pWZzIB',
    },
    {
        picture: 'apiros',
        type: 'Constructora',
        link: 'https://bit.ly/2SrdoSY',
    },
    {
        picture: 'alianza',
        type: 'Empresa',
        link: 'https://bit.ly/2SrdqKA',
    },
];

const SuccessStories = (props) => {

    const {
        title,
        reverse
    } = props;

    return (
        <>
            <section id="success-stories" className="wrapper-section success-stories">
                <Container fluid="xl">
                    <Row className="justify-content-center mt-2 mb-5">
                        <Col xs={12}>
                            <LandingSectionTitle
                                text={title ? title : 'Damos el control a los centros de distribución, edificios de oficinas y sus sedes administrativas en LATAM para que optimicen al máximo su infraestructura'}
                                alignment="center"
                                theme="dark"
                                line={false}
                            />
                        </Col>
                    </Row>

                    <Row className={(reverse && reverse === true) ? 'flex-column-reverse' : ''}>
                        <Col xs={12} className={reverse ? 'story-col-reverse' : 'story-col-no-reverse'}>
                            <Row>
                                <MobileTablet>
                                    <Col xs={12}>
                                        <Carousel
                                            responsive={responsive}
                                            ssr={true} // means to render carousel on server-side.
                                            keyBoardControl={false}
                                            arrows={false}
                                            removeArrowOnDeviceType={['desktop']}
                                            transitionDuration={800}
                                            infinite={true}
                                            autoPlay={true}
                                            autoPlaySpeed={8000}
                                            className='text-center plans-carousel'
                                            containerClass="react-multi-carousel-list success-stories-carousel-list"
                                            showDots
                                        >
                                            {successStoriesData.map((story, i) =>
                                                <SuccessStoryCard key={i} {...story} />
                                            )}
                                        </Carousel>
                                    </Col>
                                </MobileTablet>
                                <Desktop>
                                    {successStoriesData.map((story, i) => <Col xs={12} md={4} key={i}>
                                        <SuccessStoryCard {...story} />
                                    </Col>)}
                                </Desktop>
                            </Row>
                        </Col>

                        <Col xs={12} className={reverse ? 'other-success-stories-reverse' : 'other-success-stories-no-reverse'}>
                            <Row>
                                <Col xs={12}>
                                    <div className="success-stories-divider"></div>
                                </Col>

                                <Col xs={12}>
                                    <h3 className="success-stories-second-title">Conoce otros de nuestros casos de éxito</h3>
                                </Col>
                            </Row>
                        </Col>

                        <MobileTablet>
                            <Col xs={12}>
                                <Carousel responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    keyBoardControl={true}
                                    removeArrowOnDeviceType={['tablet', 'desktop']}
                                    transitionDuration={800}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={5000}
                                    className='text-center customers-carousel'
                                    containerClass="react-multi-carousel-list customers-carousel-list"
                                    arrows={true}
                                >
                                    {customers.map((item, i) =>
                                        <CustomerItem key={i} {...item} />
                                    )}
                                </Carousel>
                            </Col>
                        </MobileTablet>
                        <Desktop>
                            <Col xs={12}>
                                <div className="customers-logos-wrapper">
                                    {customers.map((item, i) =>
                                        <CustomerItem key={i} {...item} />)}
                                </div>
                            </Col>
                        </Desktop>
                    </Row>
                </Container>
            </section>
        </>
    );
};

const SuccessStoryCard = (props) => {
    const {
        pic,
        companyName,
        title,
        logo,
        link,
    } = props;

    return (
        <div className="success-story-card">
            <div className="story-card-image">
                <LazyLoadImage
                    className="story-card-img"
                    alt={`Queo Access - ${companyName}`}
                    src={`${bucketURL}/media/web/${pic}.webp`}
                    width="350px" height="200px" />
                <div className="story-card-badge">{companyName}</div>
            </div>
            <div className="story-card-data">
                <h2 className="story-card-title">{title}</h2>
                <div className="story-card-footer">
                    <div className="story-card-logo-wrapper">
                        <LazyLoadImage
                            className="story-card-logo"
                            alt={`Queo Access - ${companyName}`}
                            src={`${bucketURL}/media/web/${logo}.webp`}
                            width="80px" height="auto" />
                    </div>
                    <a className="story-card-link" href={link} aria-label={'Leer más sobre ' + title}>Leer más</a>
                </div>
            </div>
        </div>
    );
};

const CustomerItem = (customer) => {
    const {
        picture,
        link,
        companyName
    } = customer;

    return (
        <div className="customer-pic-container">
            <a href={link} aria-label={'Leer más sobre ' + picture}>
                <LazyLoadImage
                    className="story-card-logo"
                    alt={`Queo Access - ${companyName}`}
                    src={`${bucketURL}/media/web/customers/${picture}.webp`}/>
            </a>
        </div>
    );
};

export default SuccessStories;

