import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DownloadLinks from '../components/common/download-links';

const Download = () => {
    
    return (
        <section
            id="descargar"
            className="wrapper-section download justify-content-center"
        >
            <Container>
                <Row>
                    <Col xs={12} xl={6} className="offset-xl-6">
                        <Row>
                            <Col xs={12}>
                                <h2 className="text-center download-title mt-3">
                                    Descarga nuestra App
                                </h2>
                                <h5 className="text-center download-description my-1">
                                    Nuestra app Queo Access es la llave de
                                    acceso a tu copropiedad, ya sea tu edificio,
                                    oficina o parqueadero.
                                </h5>
                            </Col>

                            <Col xs={12}>
                                <DownloadLinks align="center" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
export default Download;
