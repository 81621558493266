import React, { useRef, useEffect } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { Desktop } from '../components/common/responsive-devices';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const HeroProduct = (props) => {

    const {
        mainTitle,
        title,
        video,
        product,
        customClass,
    } = props;

    const videoPath = `${bucketURL}/media/landings/video/`;

    const videoRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            if (videoRef.current) {
                try {
                    videoRef.current.play();
                } catch (error) {
                    console.error(error);
                }
            }
        }, 3000);
    }, []);

    return (
        <>
            <section className={['product-section-hero height d-flex justify-content-center align-items-center', customClass].join(' ')}>
                <Desktop>
                    <video loop autoPlay muted ref={videoRef}>
                        <source src={videoPath + video + '.webm'} type="video/webm" />
                        <source src={videoPath + video + '.mp4'} type="video/mp4" />
                    </video>
                </Desktop>
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col md={12}>
                            <div className="hero-content-container">
                                <h1 className="title-hero text-center text-white my-0">{(!mainTitle) ? 'Convierte tu oficina en un espacio inteligente con': mainTitle}</h1>
                                <h1 className="title-hero bold text-center text-orange">{title}</h1>
                                <Button href={ product ? `/asesoria-gratis?product=${product}` : '/asesoria-gratis'} block className="mt-3 button bg-transparent rounded-pill mx-auto try-button">
                                    ¡Quiero más información!
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HeroProduct;