// Sizes taken from bootstrap at
// https://github.com/twbs/bootstrap/blob/943bef258d661e0d752f2c4f129952fe2a9de47e/scss/_variables.scss#L283
export const minSizes = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
};

export function makeQuery(size) {
    if (!(size in minSizes)) {
        throw new Error('Unrecognized media query size');
    }

    const minWidth = minSizes[size];
    return `only screen and (min-width: ${minWidth}px)`;
}

const mediaQueries = {
    xs: makeQuery('xs'),
    sm: makeQuery('sm'),
    md: makeQuery('md'),
    lg: makeQuery('lg'),
    xl: makeQuery('xl'),
};

export default mediaQueries;
