import React from 'react';
import Picture from './Picture';

const DownloadLinks = (props) => {
    const { align } = props;
    return (
        <>
            <div className={'wrapper-store-links ' + align}>
                <a
                    className="store-link" 
                    aria-label="descargar queo access iphone"
                    href="https://apps.apple.com/co/app/queo-access/id1464235337"
                >
                    <Picture
                        pathName="appstore.webp"
                        className="store-link-img"
                        width="192px" height="77px" 
                        alt="App Store Queo Access"
                    />
                </a>

                <a
                    className="store-link"
                    href="https://play.google.com/store/apps/details?id=co.com.queo.pass&hl=es_CO"
                    target="_blank"
                    aria-label="descargar queo access android"
                    rel="noopener noreferrer"
                >
                    <Picture 
                        pathName="playstore.webp"
                        className="store-link-img" 
                        width="192px" height="74px" 
                        alt="Play Store Queo Access"/>
                </a>
            </div>
        </>
    );
};

export default DownloadLinks;