import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// Provide either imgName or src

const Picture = (props) => {
    const {
        src,
        pathName,
        pictureProps,
        ...otherProps
    } = props;

    const bucketURL = process.env.AWS_S3_BUCKET_URL ? process.env.AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';
    const url = src || `${bucketURL}/media/web/${pathName}`;
    return (
        <picture {...pictureProps}>
            <LazyLoadImage src={url} {...otherProps} />
        </picture>
    );
};


export default Picture;
