import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Picture from '../components/common/Picture';

const Support = () => {
    return (
        <section
            id="soporte"
            className="wrapper-section contact justify-content-center"
        >
            <Container>
                <Row className="flex-xs-column-reverse flex-md-row align-items-start">
                    <Col xs={12} xl={5}>
                        <Row>
                            <Col xs={12} sm={6} xl={12}>
                                <div className="contact-info-wrapper">
                                    <div className="contact-icon-wrapper">
                                        <Picture pathName="email.webp" />
                                    </div>
                                    <h4 className="contact-info-title">Correo</h4>
                                    <p className="contact-info-description">
                                        <a href="mailto:soporte@queo.com.co">Escríbenos un mensaje</a> y lo responderemos a la mayor brevedad posible.
                                    </p>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} xl={12}>
                                <div className="contact-info-wrapper">
                                    <div className="contact-icon-wrapper">
                                        <Picture pathName="phone-call.webp" />
                                    </div>
                                    <h4 className="contact-info-title">Teléfono de soporte</h4>
                                    <p className="contact-info-description">
                                        <a href="https://wa.link/khj6e8" rel="noreferrer" target="_blank">+57 314 364 8421</a>
                                        <br/>
                                        De lunes a viernes de 9:00 a.m. a 6:00 p.m. <strong>Hora de Colombia</strong>.
                                    </p>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} xl={12}>
                                <div className="contact-info-wrapper">
                                    <div className="contact-icon-wrapper">
                                        <Picture pathName="info.webp" />
                                    </div>
                                    <h4 className="contact-info-title">¿Quieres adquirir Queo Access?</h4>
                                    <p className="contact-info-description">
                                        <a href="https://wa.link/oj4c0x" rel="noreferrer" target="_blank">+57 311 644 1652</a>
                                        <br/>
                                        De lunes a viernes de 9:00 a.m. a 6:00 p.m. <strong>Hora de Colombia</strong>.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} xl={7}>
                        <Row>
                            <Col xs={12}>
                                <div className="powr-form-builder" id="c6b8a1eb_1695221914"></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Support;
