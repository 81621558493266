import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import Picture from '../components/common/Picture';

const SocialsNetworks = () => {

    return (
        <section className='social-networks-section'>
            <Row className="justify-content-center mx-auto py-4">
                <Col xs={8} sm={6} md={4} lg={2}>
                    <div className="social-networks-wrapper">
                        <a href="https://www.instagram.com/queocolombia/" target="_blank" rel="noreferrer">
                            <Picture
                                pathName="instagram.svg"
                                svg="true"
                                className="mx-auto"
                            />
                        </a>

                        <a href="https://www.facebook.com/queocolombia/" target="_blank" rel="noreferrer">
                            <Picture
                                pathName="facebook.svg"
                                svg="true"
                                className="mx-auto"
                            />
                        </a>

                        <a href="https://twitter.com/QueoColombia_" target="_blank" rel="noreferrer">
                            <Picture
                                pathName="twitter.svg"
                                svg="true"
                                className="mx-auto"
                            />
                        </a>

                        <a href="https://www.linkedin.com/company/queocolombia/" target="_blank" rel="noreferrer">
                            <Picture
                                pathName="linkedin.svg"
                                svg="true"
                                className="mx-auto"
                            />
                        </a>

                        <a href="https://www.youtube.com/channel/UCcIsCUS1tqOipB2fHYq8iuw" target="_blank" rel="noreferrer">
                            <Picture
                                pathName="youtube.svg"
                                svg="true"
                                className="mx-auto"
                            />
                        </a>
                    </div>
                </Col>
            </Row>
            <style jsx>{`
                .social-networks-wrapper {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                }

                a {
                    color: rgb(59, 59, 59);
                }
            `}</style>
        </section>

    );
};

if (typeof window !== 'undefined') {
    if (document.getElementById('social-footer')) {
        const container = document.getElementById('social-footer');
        const root = createRoot(container);
        root.render(<SocialsNetworks />);
    }
}
export default SocialsNetworks;