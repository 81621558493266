import React from 'react';
import {
    NavbarQueo,
    SocialsNetworks,
    Footer
} from '../sections/LandingSections';
import {
    HeroProduct,
    Intro,
    ProductFeatures,
    ProductVideo,   
} from '../sections/LandingCommonSections';

const LandingLockers = () => {
    return (
        <>
            <main className="main-landing-wrapper">
                <NavbarQueo />
                <HeroProduct video="lockers" title="Reservas de lockers inteligentes" product="lockers" customClass="lockers"/>
                <Intro intro="Bríndales a tus empleados un espacio seguro e inteligente y libre de contacto físico donde puedan dejar y guardar sus pertenencias mientras se encuentren en las instalaciones, además podrás administrarlos de acuerdo a las políticas de la compañía."/>
                <ProductFeatures title="Reserva de lockers inteligentes" features={features} image="lockers" product="lockers" />
                <ProductVideo video="lockers" title="Conoce cómo funciona" subtitle="Queremos ofrecerte facilidad a través de la tecnología. Es momento de hacer de tu oficina, un espacio inteligente." />
                <SocialsNetworks />
                <Footer />
            </main>
            <style jsx>{`
                :global(html) {
                    scroll-behavior: smooth;
                }
                .main-landing-wrapper {
                    overflow-x: hidden;
                }

                .main-landing-wrapper > * {
                    max-width: 100%;
                }
            `}</style>
        </>
    );
};

const features = [
    {
        title: 'Acceso sin llaves',
        description: 'Por medio de la aplicación de Queo Access podrás abrir o liberar tu locker sin contacto físico cuando lo necesites.',
        icon: 'fi-rr-key',
    },

    {
        title: 'Disponibilidad de los casilleros tiempo real',
        description: 'Permite, a través de un indicativo visual, ver la disponibilidad de los lockers inteligentes para hacer uso de estos o para realizarles seguimiento desde la administración.',
        icon: 'fi-rr-time-check',
    },

    {
        title: 'Informes de uso de los casilleros',
        description: 'Nuestra plataforma te permitirá tener un control informativo del uso de los lockers para saber desde cuáles son las horas con mayor afluencia hasta quién usa los casilleros con más frecuencia.',
        icon: 'fi-rr-stats',
    },

    {
        title: 'Reserva de locker',
        description: 'Con Queo Spaces tus empleados podrán reservar, de manera fácil, desde su celular un locker inteligente para guardar sus pertenencias y liberarlo cuando dejen de usarlo para que otra persona lo pueda reservar.',
        icon: 'fi-rr-smartphone',
    },
];

export default LandingLockers;