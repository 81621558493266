import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import LandingSectionTitle from '../components/common/section-title';
import ProductFeature from './ProductFeature';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const ProductFeatures = (props) => {

    const {
        title,
        features,
        image,
        product,
    } = props;

    const imagesPath = `${bucketURL}/media/landings/images/`;

    return (
        <>
            <section className="product-section-features height d-flex justify-content-center align-items-center">
                <Container>
                    <Row className="my-5 align-content-center justify-content-center">
                        <Col md={4}>
                            <LazyLoadImage
                                className="demo-image"
                                alt={product}
                                src={imagesPath + image + '.gif'}
                                width="350px"
                                height="560px" />
                        </Col>

                        <Col md={8}>
                            <Row>
                                <Col xs={12}>
                                    <LandingSectionTitle
                                        text={title}
                                        alignment="center"
                                        theme="light"
                                    />
                                </Col>

                                <Col xs={12}>
                                    <div className="queo-product-features">
                                        {features.map((feature, i) =>
                                            <ProductFeature key={i} {...feature} />
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Button href={product ? `/asesoria-gratis?product=${product}` : '/asesoria-gratis'} block className="mt-3 button bg-orange light rounded-pill mx-auto try-button">
                                        ¡Quiero más información!
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};



export default ProductFeatures;