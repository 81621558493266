import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import LandingSectionTitle from '../components/common/section-title';
import OneStepFeature from '../components/one-step/feature';

const OneStep = () => {
    return (
        <>
            <section id="one-step" className="wrapper-section one-step">
                <Container fluid="xl">
                    <Row className="justify-content-center mt-2">
                        <Col>
                            <LandingSectionTitle
                                text="Estás a un paso de tener un espacio inteligente"
                                alignment="center"
                                theme="light"
                                lineWidth="lw-30"
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-2">
                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Asistencia:"
                                icon="gear"
                                feature="estamos siempre contigo 24/7 dándote el mejor servicio."
                            />
                        </Col>

                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Facilidad:"
                                icon="check"
                                feature="fácil uso desde tu teléfono y administración remota."
                            />
                        </Col>

                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Autogestión:"
                                icon="devices"
                                feature="100% auto gestionable y con trazabilidad de todos los usuarios."
                            />
                        </Col>
                    </Row>

                    <Row className="justify-content-center mt-2">
                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Aplicación:"
                                icon="icon-app"
                                feature="la app Queo Access permite ingresar desde tu celular sin contacto físico, escaneando el código QR."
                            />
                        </Col>

                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Seguridad:"
                                icon="security"
                                feature="mediante la tecnología buscamos minimizar incidentes o criminalidad."
                            />
                        </Col>

                        <Col xs={12} lg={4}>
                            <OneStepFeature 
                                title="Infraestructura:"
                                icon="cloud"
                                feature="la información se encuentra resguardada en la nube de AWS, permitiendo tener acceso remoto."
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default OneStep;