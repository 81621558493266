import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const bucketURL = process.env.AWS_S3_BUCKET_URL ? process.env.AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const ServicesFeature = (props) => {
    const {
        image,
        title,
        description,
        link,
        linkText
    } = props;

    return (
        <div className="services-feature">
            <div className="feature-header-wrapper">
                <LazyLoadImage
                    className="service-image"
                    alt={description}
                    src={`${bucketURL}/media/web/services/${image}.webp`} />
            </div>

            <div className="service-content">
                <h2 className="text-2xl medium service-title">{ title }</h2>
                <p className=" text-xl service-description">{ description }</p>
                <a href={link} className="text-2xl service-link">{linkText}</a>
            </div>
        </div>
    );
};

export default ServicesFeature;