import './App.scss';
import { Routes, Route } from "react-router-dom";

import LandingHome from './pages/home';
import LandingAccess from './pages/access';
import LandingParking from './pages/parking';
import LandingWorkSpaces from './pages/work-spaces';
import LandingRooms from './pages/rooms';
import LandingLockers from './pages/lockers';
import LandingFreeTrial from './pages/free-trial';
import LandingPrivacy from './pages/privacy';

import AcceptanceCookies from './components/AcceptanceCookies';

function App() {
  return (
    <div>
      <AcceptanceCookies />
      {/* Routes nest inside one another. Nested route paths build upon
        parent route paths, and nested route elements render inside
        parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/">
          <Route index element={<LandingHome />} />
          <Route path="control-de-acceso" element={<LandingAccess />} />
          <Route path="optimizacion-de-parqueaderos" element={<LandingParking />} />
          <Route path="puestos-colaborativos" element={<LandingWorkSpaces />} />
          <Route path="salas" element={<LandingRooms />} />
          <Route path="lockers-inteligentes" element={<LandingLockers />} />
          <Route path="asesoria-gratis" element={<LandingFreeTrial />} />
          <Route path="privacidad" element={<LandingPrivacy />} />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
      </Routes>
    </div>
  )
}

export default App;
