import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Picture from '../components/common/Picture';

const app_web_url = process.env.REACT_APP_QUEOACCESS_URL ?? 'queoacess.com';

const NavbarExtraQueo = () => {
    return (
        <>
            <div className="queo-navbar-extra">
                <Container>
                    <Row className="justify-content-end">
                        <Col xs={6}>
                            <div className="queo-navbar-user-menu">
                                <a href={`${app_web_url}/login`}
                                    className="queo-navbar-login-btn">
                                    <Picture
                                        pathName="user-account.svg"
                                        svg="true" 
                                        className="not-logged-in-user" 
                                        alt="Ingresar al administrador"
                                    />
                                    <span>IR A QUEO ACCESS</span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default NavbarExtraQueo;
