import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MobileTablet, Desktop } from '../components/common/responsive-devices';
import LandingSectionTitle from '../components/common/section-title';

const bucketURL = process.env.REACT_APP_AWS_S3_BUCKET_URL ? process.env.REACT_APP_AWS_S3_BUCKET_URL : 'https://queo-prod-s3.s3-us-west-2.amazonaws.com';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1366 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1365, min: 1023 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1022, min: 834 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 833, min: 0 },
        items: 1
    }
};

const mediaData = [
    {
        image: 'forbes',
        alt: 'Revista Forbes',
        link: 'https://forbes.co/2020/07/16/25-empresas/25-resilientes-queo-access/',
    },
    {
        image: 'larepublica',
        alt: 'Diario La República',
        link: 'https://www.larepublica.co/internet-economy/las-10-aplicaciones-colombianas-que-usted-debe-conocer-3145273',
    },
    {
        image: 'rcnradio',
        alt: 'RCN Radio',
        link: 'https://www.rcnradio.com/colombia/regreso-las-oficinas-como-adaptarlas-ante-los-retos-de-la-pandemia?amp=',
    },
    {
        image: 'nuevosiglo',
        alt: 'El Nuevo Siglo',
        link: 'https://www.elnuevosiglo.com.co/articulos/04-14-2021-empresario-como-prepararse-para-un-regreso-las-oficinas',
    },
    {
        image: 'portafolio',
        alt: 'Portafolio',
        link: 'https://www.portafolio.co/tendencias/como-prepararse-para-un-regreso-a-las-oficinas-551389',
    },
    {
        image: 'bluradio',
        alt: 'Blu Radio',
        link: 'https://bit.ly/2TkkdpC',
    },
];

const WhoMentionUs = () => {
    return (
        <>
            <section id="who-mention-us" className="wrapper-section who-mention-us">
                <Container fluid="xl">
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <LandingSectionTitle
                                text="Nuestra historia en los medios"
                                alignment="center"
                                theme="light"
                                line={false}
                                otherClasses={['who-mention-us-title']}
                            />
                        </Col>
                        <Col xs={12}>
                            <p className="who-mention-us-description">
                                Para leer la nota de tu interés, haz clic en el logo.
                            </p>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <MobileTablet>
                            <Col xs={12}>
                                <Carousel
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    keyBoardControl={false}
                                    arrows={true}
                                    removeArrowOnDeviceType={['desktop']}
                                    transitionDuration={800}
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={8000}
                                    className='text-center plans-carousel'
                                    containerClass="react-multi-carousel-list"
                                    showDots={false}
                                >
                                    {mediaData.map((media, i) =>
                                        <a
                                            key={i}
                                            href={media?.link}
                                            target="_blank"
                                            rel="noreferrer"
                                            aria-label={media?.alt}
                                            className="who-mention-us-medio-link">
                                            <LazyLoadImage
                                                className="who-mention-us-logo"
                                                alt={media?.alt}
                                                src={`${bucketURL}/media/web/medios/${media?.image}.webp`} />
                                        </a>
                                    )}
                                </Carousel>
                            </Col>
                        </MobileTablet>

                        <Desktop>
                            {mediaData.map((media, i) => <Col xs={12} md={2} key={i} className="text-align center">
                                <a href={media?.link} aria-label={media?.alt} className="who-mention-us-medio-link" target="_blank" rel="noreferrer">
                                    <LazyLoadImage
                                        className="who-mention-us-logo"
                                        alt={media?.alt}
                                        src={`${bucketURL}/media/web/medios/${media?.image}.webp`} />
                                </a>
                            </Col>)}
                        </Desktop>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default WhoMentionUs;