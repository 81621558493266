import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

const AcceptanceCookies = () => {
    const c_name = 'queo-cookies';
    const [showCookieMessage, setShowCookieMessage] = useState(null);

    useEffect(() => {
        const getCookie = () => {
            let c_value = document.cookie;
            let c_start = c_value.indexOf(' ' + c_name + '=');
            if (c_start === -1){
                c_start = c_value.indexOf(c_name + '=');
            }
            if (c_start === -1){
                c_value = null;
                setShowCookieMessage(null);
            }else{
                c_start = c_value.indexOf('=', c_start) + 1;
                let c_end = c_value.indexOf(';', c_start);
                if (c_end === -1){
                    c_end = c_value.length;
                }
                c_value = unescape(c_value.substring(c_start,c_end));
                setShowCookieMessage('1');
            }
            return c_value;
        };

        getCookie();
    }, []);

    const setCookie = (value, exdays) => {
        let exdate=new Date();
        exdate.setDate(exdate.getDate() + exdays);
        let c_value = escape(value) + ((exdays === null) ? '' : '; expires='+exdate.toUTCString());
        document.cookie=c_name + '=' + c_value;
        setShowCookieMessage('1');
    };

    return (
        <>
            {showCookieMessage !== '1' && <div className="acceptance-cookies-bar">
                <Container>
                    <Row className="flex-direction-xs-column flex-direction-md-row">
                        <Col xs={12} md={8} lg={9}>
                            <p className="acceptance-cookies-message">Este sitio web usa cookies, si permanece aquí acepta su uso. Si continúa navegando consideramos que acepta el uso de cookies.</p>
                        </Col>
                        <Col xs={12} md={4} lg={3}>
                            <div className="acceptance-cookies-btns-wrapper">
                                <Button className="acceptance-ok-button" onClick={() => setCookie('1',365)}><b>Acepto</b></Button>
                                <Button className="acceptance-moreinfo-button" href="https://queo.com.co/privacidad" target="_blank" rel="noopener noreferrer">Más información</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>}
            <style jsx>{`
                .acceptance-cookies-btns-wrapper {
                    display: flex;
                    justify-content: space-around;
                }
            `}</style>
        </>
    );
};

export default AcceptanceCookies;
